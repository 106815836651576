/**
 * Created by osirvent on 18/11/2015.
 */
angular
    .module('annexaApp')
    .constant('ContextMenu', window.$.contextMenu)
    .constant('api', {
        getAlertsByFilter:'./api/alerts/getAlertsByFilter',
        getAlertsByFilterPaginated:'./api/alerts/getAlertsByFilterPaginated',
        updateReadAlerts:'./api/alerts/updateReadAlerts',
        millisecondsInterval:10000
    })
    .constant('globalModals', {
        adminBaseNew: {
            title: 'New element',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-sm-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        adminBaseEdit: {
            title: 'Edit element',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateElement(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        dossierDocFromTemplate: {
            title: 'global.literals.dossier_doc_from_template_title',
            size: 'modal-md',
            icon: 'fa fa-user',
            annexaFormly: {},
            boxes: [],
            alerts: [],
            submitModal: function () {}
        },
        receiverNew: {
            title: 'global.literals.new_receiver_modal_title',
            size: 'modal-md',
            icon: 'fa fa-user',
            annexaFormly: {},
            boxes: [],
            alerts: [],
            submitModal: function () {}
        },
        registerNew: {
            title: 'global.literals.new_register_modal_title',
            size: 'modal-lg',
            icon: 'fa fa-user',
            annexaFormly: {},
            boxes: [],
            alerts: [],
            submitModal: function () {}
        },
        receiverAdd: {
            title: 'global.literals.receiver',
            size: 'modal-sm',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'idReceiver',
                                type: 'annexaHidden',
                                className: '',
                                templateOptions: {
                                    type: 'hidden',
                                    label: '',
                                    required: false
                                }
                            },
                        	{
                        		key: 'thirdType',
                        		type: 'annexaRadioCheckboxRow',
                        		className: 'col-sm-12',
                        		defaultValue: 'interested',
                        		data: {
                                    informed: true,
                                    row: true,
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                        		templateOptions: {
                        			type: 'radio',
                        			radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: true,
                                    validate: false,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    label: 'global.literals.type'
                        		},
                        		controller: function ($scope, $filter) {
                                    $scope.to.options = [
                                        {id:'interested', description: $filter("translate")('global.literals.interested')},
                                        {id:'representative', description: $filter("translate")('global.literals.representative')}
                                    ];
                                }
                        	},
                            {
                                key: 'representated',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.representated',
                                    valueProp: 'id',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false
                                },
                                hideExpression: function($viewValue, $modelValue, scope) {
                                	return scope.model.thirdType != 'representative';
                                },
                                controller: ['$scope', function($scope) {
                                    $scope.to.options = [];

                                    if ($scope.formState && $scope.formState.editReceiver) {
                                    	//Editar destinatario
                                    	var idReceiver = $scope.formState.idReceiver;
                                    	var idThird = $scope.formState.idThird;
                                    	var idReceiverRepresentated = $scope.formState.idReceiverRepresentated;                                    	
                                    	
                                    	var representeds = $linq($scope.formState.data).where("x => x.representated != null && x.representated.id != " + idReceiverRepresentated).select("x => x.representated").toArray();
                                    	if (idReceiver) {
                                    		var interested = $linq($scope.formState.data).where("x => x.interested && x.id != " + idReceiver).except(representeds,"(x,y) => x.id == y.third.id").toArray();
                                    	} else {
                                    		var interested = $linq($scope.formState.data).where("x => x.interested && x.third.id != " + idThird).except(representeds,"(x,y) => x.id == y.third.id").toArray();
                                    	}
                                    } else {
                                    	//Nuevo destinatario
                                    	var representeds = $linq($scope.formState.data).where("x => x.representated != null").select("x => x.representated").toArray();
                                    	var interested = $linq($scope.formState.data).where("x => x.interested").except(representeds,"(x,y) => x.id == y.third.id").toArray();
                                    }

                                    _.forEach(interested, function (value) {
                                        var representated = value.third;
                                        if(value.third.name) {
                                            representated.label = value.third.name + ' ' + value.third.surename1 + ((value.third.surename2)? ' ' + value.third.surename2 : '' ) + ' (' + value.third.identificationDocument + ')';
                                        }else{
                                            representated.label = value.third.corporateName + ' (' + value.third.identificationDocument + ')';
                                        }
                                        $scope.to.options.push(representated);
                                    });
                                }]
                            },
                            {
                                key: 'roleInterested',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.optionsRoleInterested | filter: $select.search',
                                    label: 'global.literals.roleInterested',
                                    valueProp: 'id',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false
                                },   
                                hideExpression: function($viewValue, $modelValue, scope) {
                                	var hide = true;
                                	if (scope.model.thirdType == 'interested' && scope.$root.existsOptionsRoleInterested) {
                                		hide = false;
                                	}
                                	return hide;
                                },
                                controller: ['$scope', 'RestService','Language', 'GlobalDataFactory', function($scope, RestService, Language, GlobalDataFactory) {
                                	$scope.to.labelProp = Language.getActiveColumn();
                                	$scope.to.optionsRoleInterested = GlobalDataFactory.roleInteresteds;                                	
                                }]                                
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {
            }
        },
        receiverEdit: {
            title: 'global.literals.edit_receiver_modal_title',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'editReceiver',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'checkHTML',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    labelHtml: 'labelHtml',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ],
                        wrapper: 'annexaRow'
                    }
                ]
            },
            submitModal: function () {
            }
        },
        newFolder: {
            title: 'global.literals.newFolder',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'name',
                        type: 'annexaInputRow',
                        className: 'col-sm-12',
                        data: {
                            row: true,
                            colClass: '',
                            labelClass: 'label-strong',
                            informed: true,
                            clear: function($event,model,key, $select) {
                                $event.stopPropagation();
                                model[key] = undefined;
                                if($select) {
                                    $select.selected = undefined;
                                    $select.search = undefined;
                                }
                            }
                        },
                        templateOptions: {
                            type: 'text',
                            label: 'global.literals.name',
                            required: true,
                            focus: false
                        }
                    }
                ]
            },
            submitModal: function () {
            }
        },
        cancelDossier: {
            title: 'global.tram.literals.cancel_dossier',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'reason',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.reason',
                                    required: true,
                                    focus: true
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        completeTransactionPopup:{
            title: 'global.tram.literals.complete_transaction_title',
            size: 'modal-lg',
            icon: 'fa fa-check',
            submitModal: function () {
            },
            alerts: []
        },
        completeTransactionPopupGuided:{
            title: 'global.tram.literals.complete_transaction_title',
            size: 'modal-lg',
            icon: 'fa fa-check',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'nextDossierTransactionInfo',
                                type: 'annexaNextDossierTransactionInfoRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    options:{},
                                    transaction:{}
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        goBackTransactionPopup:{
            title: 'global.tram.literals.goback_transaction_title',
            size: '',
            icon: 'fa fa-mail-reply',
            annexaFormly: {
                fields: [
                    {
                        key: 'motiu',
                        type: 'annexaTextAreaRow',
                        className: 'col-sm-12 daughter-label-strong',
                        data: {
                            informed: true,
                            row: true
                        },
                        templateOptions: {
                            type: 'text',
                            required: true,
                            focus: false,
                            label: '',
                            updateOnBlur:false
                        }
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        goBackTransactionPopupWizard: {
            title: 'global.tram.literals.goback_transaction_title',
            size: 'modal-lg',
            icon: 'fa fa-mail-reply',
            wizard: {
                modal: undefined,
                finishWizard: function () {
                    if(this.modal) {
                        this.modal.submitModal();
                    }
                },
                exitValidation: function(form) {
                    return form && !form.$invalid;
                },
                steps: [
                    {
                        title: 'global.literals.rejectReason',
                        key: 'deleteTransaction',
                        disabled: false,
                        disableNext: function(model, wizard) {
                            var disabled = false;
                            if(model && model.motiu){
                                disabled = true;
                            }
                            return !disabled;
                        },
                        exitValidation: function(model) {
                            var selected = false;

                            if(model && model.motiu){
                                selected = true;
                            }

                            return selected;
                        },
                        annexaFormly: {
                            fields: [
                                {
                                    key: 'motiu',
                                    type: 'annexaTextAreaRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    data: {
                                        informed: true,
                                        row: true
                                    },
                                    templateOptions: {
                                        type: 'text',
                                        required: true,
                                        focus: false,
                                        label: '',
                                        updateOnBlur:false
                                    }
                                }
                            ]
                        }
                    },
                    {
                        title: 'global.literals.profile_handler_finally',
                        key: 'deleteTransactionNewTram',
                        disabled: false,
                        disableNext: function(model, wizard) {
                            return true;
                        },
                        exitValidation: function(model) {
                            var selected = false;

                            if(model && model.motiu){
                                selected = true;
                            }

                            return selected;
                        },
                        annexaFormly: {
                            fields: [
                                {
                                    key: 'dossierTransactionType',
                                    type: 'annexaRadioCheckboxRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    data: {
                                        informed: true,
                                        row: true
                                    },
                                    templateOptions: {
                                        type: 'radio',
                                        optionsAttr: 'bs-options',
                                        required: true,
                                        validate: true,
                                        options:[],
                                        ngOptions:'option[to.valueProp] as option in to.options',
                                        valueProp: 'id',
                                        labelProp: 'value'
                                    },
                                    hideExpression:function($viewValue, $modelValue, scope) {
                                        if(scope.model.haveReopenPermission) {
                                            return false;
                                        }else {
                                            return true;
                                        }
                                    },
                                    controller: ['$scope', '$filter', function ($scope, $filter) {
                                        $scope.to.options = [{id:1, value:$filter("translate")("global.tram.literals.create_new_dossier_transaction")},{id:2, value :$filter("translate")("global.tram.literals.select_closed_dossier_transaction")}];
                                    }]
                                },
                                {
                                    key: 'profile',
                                    type: 'annexaLoadUserAndComboButtonRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    data: {
                                        informed: true,
                                        row: true
                                    },
                                    templateOptions: {
                                        type: 'text',
                                        label: '',
                                        required: false,
                                        focus: false,
                                        options: [],
                                        loadFunction: function () {},
                                        saveFunction: function () {},
                                        inputType: 'Profile',
                                        buttonLabel: 'global.literals.select',
                                        atributes: {},
                                        step: undefined,
                                        placeholder: 'global.reg.literals.profileOrUserName',
                                        showTitle: false,
                                        showOr: false
                                    },
                                    hideExpression:function($viewValue, $modelValue, scope) {
                                        if(scope.model.dossierTransactionType == 1 || !scope.model.haveReopenPermission) {
                                            return false;
                                        }else {
                                            return true;
                                        }
                                    }
                                },
                                {
                                    key: 'dossierTransactions',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    data: {
                                        informed: true,
                                        row: true,
                                        clear: function ($event, model, key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if ($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        optionsAttr: 'bs-options',
                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                        label: 'global.tram.literals.transactions',
                                        valueProp: 'id',
                                        labelProp: 'name',
                                        placeholder: '',
                                        options: []
                                    },
                                    validators: {
                                        "requiredOne": {
                                            "expression": function (viewValue, modelValue, scope) {
                                                var valid = true;

                                                return valid;
                                            }
                                        }
                                    },
                                    watcher: {
                                        type: '$watchCollection',
                                        expression: 'model',
                                        listener: function (field, _new) {
                                            if (field.formControl) {
                                                field.formControl.$validate();
                                            }
                                        }
                                    },
                                    hideExpression:function($viewValue, $modelValue, scope) {
                                        if(scope.model.dossierTransactionType == 2 && scope.model.haveReopenPermission) {
                                            return false;
                                        }else {
                                            return true;
                                        }
                                    },
                                    controller: ['$scope', 'Language', 'TramFactory', function ($scope, Language, TramFactory) {
                                        if(TramFactory.Dossier && TramFactory.Dossier.dossierTransactions){
                                            var transactions = $linq(TramFactory.Dossier.dossierTransactions).where("x => !x.actual").toArray();
                                            if(transactions) {
                                                $scope.to.options.length = 0;
                                                _.forEach(transactions, function(value) {
                                                    $scope.to.options.push({id: value.id, name: value[Language.getActiveColumn()]});
                                                });
                                            }
                                        }
                                    }]
                                }
                            ]
                        }
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        representationAdd: {
            title: 'global.literals.add_representation_modal_title',
            size: 'modal-sm',
            icon: 'fa fa-user',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'startValidityPeriod',
                                type: 'annexaDatepickerRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.startDate',
                                    required: true,
                                    validate: true,
                                    changeFunction: 'to.changeFunction',
                                    datepickerOptions: {
                                        format: 'dd/MM/yyyy',
                                        initDate: new Date(),
                                        showWeeks: false,
                                        startingDay: 1
                                    }
                                }
                            },
                            {
                                key: 'endValidityPeriod',
                                type: 'annexaDatepickerRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.endDate',
                                    required: true,
                                    validate: true,
                                    datepickerOptions: {
                                        format: 'dd/MM/yyyy',
                                        initDate: new Date(),
                                        showWeeks: false,
                                        startingDay: 1
                                    }
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                validators:{
                                    "dateRange": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(modelValue && modelValue < scope.model.startValidityPeriod){
                                                valid = false;
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        consentsAdd: {
            title: 'global.literals.newConsent',
            size: '',
            icon: 'fa fa-tag',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'thirdAddress',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed:true,
                                    row:true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.notificationChannel',
                                    valueProp: 'key',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false
                                },
                                controller:['$scope', 'Language', function($scope, Language) {
                                    var getAdressesHtml = function(contact){
                                        var thirdAddress = new ThirdAddress(undefined, Language.getActiveColumn());
                                        return thirdAddress.getAddressHtml(contact, false, true);
                                    }
                                    var contacts = [];

                                    var contactBoxContent = undefined;

                                    if(angular.element('#new-third').length != 0) {
                                        contactBoxContent = angular.element('#new-third').scope().$parent.entity.boxes[0].boxDefinition.content;
                                    } else if (angular.element('#edit-third').length != 0) {
                                        contactBoxContent = angular.element('#edit-third').scope().contactBox.boxDefinition.content;
                                    }

                                    _.forEach(contactBoxContent, function (contact, key) {
                                        contact.name = getAdressesHtml(contact);
                                        contacts.push(contact);
                                    });

                                    $scope.to.options = contacts;
                                }]
                            },
                            {
                                key: 'consentType',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed:true,
                                    row:true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.type',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [
                                        { id: 'ALL', name: 'global.literals.ALL' },
                                        { id: 'FAMILY_PROCEDURE', name: 'global.literals.FAMILY_PROCEDURE' },
                                        { id: 'PROCEDURE', name: 'global.literals.PROCEDURE' },
                                        { id: 'DOSSIER', name: 'global.literals.DOSSIER' }
                                    ],
                                    required: true,
                                    focus: false
                                },
                                hideExpression:function($viewValue, $modelValue, scope) {
                                    if(scope.model.thirdAddress) {
                                        return false;
                                    }

                                    return true;
                                }
                            },
                            {
                                key: 'familyProcedure',
                                type: 'annexaSelectTreeRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    label: 'global.literals.family',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller:['$scope', 'GlobalDataFactory', function($scope, GlobalDataFactory) {
                                    $scope.to.options = GlobalDataFactory.familyProceduresTree;
                                }],
                                hideExpression:function($viewValue, $modelValue, scope){
                                    if(scope.model.thirdAddress && scope.model.consentType && scope.model.consentType.id == 'FAMILY_PROCEDURE' ){
                                        return false;
                                    }

                                    return true;
                                }
                            },
                            {
                                key: 'procedure',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.PROCEDURE',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false
                                },
                                controller:['$scope', 'GlobalDataFactory', 'Language', function($scope, GlobalDataFactory, Language) {
                                    $scope.to.options = GlobalDataFactory.procedures;
                                    $scope.to.labelProp = Language.getActiveColumn();
                                }],
                                hideExpression:function($viewValue, $modelValue, scope){
                                    if(scope.model.thirdAddress && scope.model.consentType && scope.model.consentType.id == 'PROCEDURE' ){
                                        return false;
                                    }

                                    return true;
                                }
                            },
                            {
                                key: 'dossier',
                                type: 'annexaFilterInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    label: 'global.literals.DOSSIER',
                                    placeholder: 'global.literals.placeholder_search_dossiers',
                                    search: function() {},
                                    advancedSearch: function() {},
                                    advancedSearchAdd: function(object, modal) {
                                        var proccesResult = function (object) {
                                            return object.dossierNumber + " (" + object.subject + ")";
                                        };
                                        object.value = proccesResult(object.object);
                                        modal.annexaFormly.model.modal_body.dossier = object;
                                        this.close();
                                    }
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    if(scope.model.thirdAddress && scope.model.consentType && scope.model.consentType.id == 'DOSSIER' ){
                                        return false;
                                    }

                                    return true;
                                }
                            }
                        ]
                    }
                ]
            }
        },
        contactsAdd: {
            title: 'global.literals.add_contacts_modal_title',
            size: 'modal-lg',
            icon: 'fa fa-at',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'addressType',
                                type: 'annexaRadioRow',
                                className: 'col-sm-12 ',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12 b-b-lg-primary',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'radio',
                                    radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: true,
                                    validate: true,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'addressType',
                                    labelProp: 'value',
                                    focus: true
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.options = [];
                                    $scope.to.options.push({id:1, value:$filter('translate')('global.thirds.addressTypesModal.POSTAL'), addressType:'POSTAL'});
                                    $scope.to.options.push({id:2, value:$filter('translate')('global.thirds.addressTypesModal.TELEMATIC_MOBILEPHONE'), addressType:'TELEMATIC_MOBILEPHONE'});
                                    $scope.to.options.push({id:3, value:$filter('translate')('global.thirds.addressTypesModal.TELEMATIC_EMAIL'), addressType:'TELEMATIC_EMAIL'});
                                    $scope.to.options.push({id:3, value:$filter('translate')('global.thirds.addressTypesModal.POSTAL_CODE'), addressType:'POSTAL_CODE'});
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var hide = false;
                                    if(scope.model.id){
                                        hide = true;
                                    }
                                    return hide;
                                }
                            },
                            {
                                key: 'contactPrefix',
                                type: 'annexaInputRow',
                                className: 'col-sm-3',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.internationalPrefix',
                                    required: false,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'TELEMATIC_MOBILEPHONE' ){
                                        show = false;
                                    }
                                    return show;
                                },
                                validators:{
                                    "number": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(modelValue && isNaN(modelValue)){
                                                valid = false;
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            if (field.formControl.$validate) {
                                                field.formControl.$validate();
                                            } else {
                                                field.formControl[field.formControl.length - 1].$validate();
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                key: 'contactPhone',
                                type: 'annexaInputRow',
                                className: 'col-sm-9',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.profile.phone',
                                    required: true,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'TELEMATIC_MOBILEPHONE' ){
                                        show = false;
                                    }
                                    return show;
                                },
                                validators:{
                                    "number": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(modelValue && isNaN(modelValue)){
                                                valid = false;
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            if (field.formControl.$validate) {
                                                field.formControl.$validate();
                                            } else {
                                                field.formControl[field.formControl.length - 1].$validate();
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                key: 'contactEmail',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'email',
                                    label: 'global.profile.email',
                                    required: true,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'TELEMATIC_EMAIL' ){
                                        show = false;
                                    }
                                    return show;
                                }
                            },
                            {
                                key: 'postalCode',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.territory.list.postalCode',
                                    required: true,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL_CODE' ){
                                        show = false;
                                    }
                                    return show;
                                },
                                validators:{
                                    "number": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(modelValue && isNaN(modelValue)){
                                                valid = false;
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            if (field.formControl.$validate) {
                                                field.formControl.$validate();
                                            } else {
                                                field.formControl[field.formControl.length - 1].$validate();
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                key: 'territory',
                                type: 'annexaRadioRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'checkbox',
                                    radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: false,
                                    validate: true,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'defaultType',
                                    labelProp: 'value'
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL' && !scope.model.editExitAddress) {
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.options = [];
                                    $scope.to.options.push({id:1, value:$filter('translate')('global.thirds.literals.isThirdAddress')});
                                }
                            },
                            {
                                key: 'addressTypeTerritory',
                                type: 'annexaSelectRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.thirds.literals.addressType',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    clearHide: true
                                },
                                controller: ['$scope', 'Language', 'GlobalDataFactory', 'DccumentsFactory', '$rootScope', function($scope, Language, GlobalDataFactory, DccumentsFactory, $rootScope) {
                                    $scope.to.options = angular.copy(GlobalDataFactory.addressTypes);
                                    $scope.to.labelProp = Language.getActiveColumn();
                                	if(!$scope.model.addressTypeTerritory){
                                		$scope.model.addressTypeTerritory = (($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.defaultAddressType && $rootScope.app.configuration.defaultAddressType.value)?$rootScope.app.configuration.defaultAddressType.value:undefined);
                                	}
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	var show = true;
                                    if(scope.model.addressType == 'POSTAL' && scope.model.territory_){
                                        show = false;
                                    }
                                    return show;
                                }
                            },
                            {
                                key: 'address',
                                type: 'annexaFilterInputRow',
                                className: 'col-sm-8',
                                templateOptions: {
                                    required: true,
                                    label: 'global.territory.list.address',
                                    placeholder: 'global.literals.placeholder_search_address',
                                    search: function() {}
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                	var show = true;
                                    if(scope.model.addressType == 'POSTAL' && scope.model.territory_){
                                        show = false;
                                    }
                                    return show;
                                }
                            },
                            {
                                key: 'addressThird',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                	type: 'text',
                                	disabled: true,
                                    required: false,
                                    label: 'global.territory.list.address',
                                    search: function() {}
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                	var show = true;
                                    if(scope.model.addressType == 'POSTAL' && !scope.model.territory_){
                                        show = false;
                                    }
                                    return show;
                                }
                            },
                            { 
                                key: 'newAddress',
                                type: 'annexaLabelButton',
                                className: 'col-sm-4',
                                templateOptions: {
                                	buttonLabel: 'global.territory.list.newAddress',
                                	executeFunction: function() {}
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL'){ 
                                    	if(scope.model.territory_){
                                    		show = !scope.model.canCreateNewAddress;
                                    	} else {
                                    		show = false;
                                    	}
                                    }
                                    return show;
                                },
                                controller: function ($q, $scope, Language, RestService, $rootScope) {
                                    $rootScope.$on('modifyCityEvent', function(event, args) {
                                        $scope.model.street = undefined;
                                    });

                                    $rootScope.$on('modifyStateEvent', function(event, args) {
                                        $scope.model.street = undefined;
                                    });

                                    $rootScope.$on('modifyCountryEvent', function(event, args) {
                                        $scope.model.street = undefined;
                                    });
                                }
                            },
                            {
                                key: 'observations',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.observations',
                                    required: false,
                                    focus: false
                                }
                            },
                            {
                                key: 'defaultEmail',
                                type: 'annexaRadioRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'checkbox',
                                    radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: false,
                                    validate: true,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'defaultType',
                                    labelProp: 'value'
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'TELEMATIC_EMAIL' && scope.model.showDefaultByTypeEmail) {
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.options = [];
                                    $scope.to.options.push({id:1, value:$filter('translate')('global.thirds.literals.favoriteByType'), defaultType:'defaultByType'});
                                }
                            },
                            {
                                key: 'defaultPostalCode',
                                type: 'annexaRadioRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'checkbox',
                                    radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: false,
                                    validate: true,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'defaultType',
                                    labelProp: 'value'
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL_CODE' && scope.model.showDefaultByTypePostalCode) {
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.options = [];
                                    $scope.to.options.push({id:1, value:$filter('translate')('global.thirds.literals.favoriteByType'), defaultType:'defaultByType'});
                                }
                            },
                            {
                                key: 'defaultPostal',
                                type: 'annexaRadioRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'checkbox',
                                    radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: false,
                                    validate: true,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'defaultType',
                                    labelProp: 'value'
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL' && scope.model.showDefaultByTypePostal){
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.options = [];
                                    $scope.to.options.push({id:1, value:$filter('translate')('global.thirds.literals.favoriteByType'), defaultType:'defaultByType'});
                                }
                            },
                            {
                                key: 'defaultNumber',
                                type: 'annexaRadioRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'checkbox',
                                    radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: false,
                                    validate: true,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'defaultType',
                                    labelProp: 'value'
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'TELEMATIC_MOBILEPHONE' && scope.model.showDefaultByTypeMobile) {
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.options = [];
                                    $scope.to.options.push({id:1, value:$filter('translate')('global.thirds.literals.favoriteByType'), defaultType:'defaultByType'});
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        uploadFile: {
            title: '',
            size: '',
            icon: 'fa fa-file-text-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'file',
                                type: 'annexaFileUpload',
                                templateOptions: {
                                    label: 'global.literals.filename',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    accept: ''
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        uploadMultiFile: {
            title: '',
            size: '',
            icon: 'fa fa-file-text-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'file',
                                type: 'annexaMultiFileUpload',
                                templateOptions: {
                                    label: 'global.literals.filename',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    accept: ''
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        uploadFileBase64: {
            title: '',
            size: '',
            icon: 'fa fa-file-text-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'file',
                                type: 'annexaFileUploadBase64',
                                templateOptions: {
                                    label: 'global.literals.filename',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    accept: ''
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        documentNew:{
            title: 'global.literals.modalNewDocumentTitle',
            size: '',
            icon: '&#xe89c',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'typeSelect',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | languagefilter: $select.search',
                                    label: 'global.literals.type',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    clearHide: true,
                                    onSelected: function(item, options) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');

                                        $rootScope.$broadcast('newDocumentTypeSelected', { item: item });
                                    }
                                },
                                controller: ['$scope', '$rootScope', 'DccumentsFactory', 'Language', 'GlobalDataFactory', function($scope, $rootScope, DccumentsFactory, Language, GlobalDataFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = [];
                                    var types = GlobalDataFactory.documentTypes;
                                    if($scope.to.optionsAux && $scope.to.optionsAux.length > 0){
                                    	types = $scope.to.optionsAux;
                                    }
                                    _.forEach (types, function(docType){
                                    	var canUseType = true;
                                    	if($scope.originalModel && $scope.originalModel.module && $scope.originalModel.module == 'REGSALIDA'
                                    		&& !docType.includeRegisterEntry){
                                    		canUseType = false;
                                    	}
                                        if(docType.profiles && docType.profiles.length > 0){
                                            // intersection de perfil de usuario y los perfiles de documentTypeProfile
                                            var profiles = $linq(docType.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
                                                if(y.profile){
                                                    return (x.profile.id == y.profile.id);
                                                }
                                                // en este caso es por si hubiera un null, ya que admitimos todos los perfiles.
                                                else{
                                                    return true;
                                                }
                                            }).toArray();
                                            if(profiles && profiles.length > 0 && canUseType){
                                                $scope.to.options.push(angular.copy(docType));
                                            }
                                            else{
                                            }
                                        }
                                        else if(canUseType){
                                            $scope.to.options.push(angular.copy(docType));
                                        }
                                    });

                                    $scope.$on('newDocumentTypeSelected', function(event, args) {
                                        if(args.item) {
                                        	if($scope.fields && $scope.fields.length > 0 && $scope.fields[0].templateOptions && $scope.fields[0].templateOptions.optionsAux){
                                            	DccumentsFactory.typeSelected(args.item, $scope.model, $scope.fields, undefined, $scope.fields[0].templateOptions.optionsAux);
                                            }else{
                                            	DccumentsFactory.typeSelected(args.item, $scope.model, $scope.fields);
                                            }
                                        }
                                    });
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'template',
                                type: 'annexaDocumentFileTemplate',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    labelFile: 'global.literals.filename',
                                    labelTemplate: 'global.literals.template',
                                    options: [],
                                    onlyLastLevelClick: true,
                                    required: false,
                                    keyFile:'file',
                                    keyTemplate:'template',
                                    inputType:'inputType',
                                    onSelectedTemplate: function(item, options) {
                                        var DccumentsFactory = angular.element(document.body).injector().get('DccumentsFactory');
                                        DccumentsFactory.selectTemplate(this.$parent.$parent.$parent.model, item);
                                    },
                                    onSelectedFile: function(element){
                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifySelectedFile', { element: element });
                                    }
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    var DccumentsFactory = angular.element(document.body).injector().get('DccumentsFactory');
                                    return DccumentsFactory.hideDocumentTemplate(scope.model);
                                },
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            //var DccumentsFactory = angular.element(document.body).injector().get('DccumentsFactory');
                                            var DccumentsFactory = angular.element(document.body).injector().get('DccumentsFactory');
                                            return DccumentsFactory.requiredValidator(scope.model,  scope.fields[3]);
                                        }
                                    }
                                },
                                watcher: [
                                    {
                                        type: '$watchCollection',
                                        expression: 'model.typeSelect',
                                        listener: function(field, _new, aux,scope) {
                                            var DccumentsFactory = angular.element(document.body).injector().get('DccumentsFactory');
                                            field.templateOptions.options = DccumentsFactory.getTemplateOptions(scope.model);
                                            scope.$broadcast('treeTemplatesChange');
                                            if(field.formControl){
                                                field.formControl.$validate();
                                            }
                                        }
                                    },
                                    {
                                        type: '$watchCollection',
                                        expression: 'model.file',
                                        listener: function(field, newValue, oldValue, scope, stopWatching) {
                                            if(newValue !== oldValue) {
                                                if(field.formControl){
                                                    field.formControl.$validate();
                                                }
                                            }
                                        }
                                    },
                                    {
                                        type: '$watchCollection',
                                        expression: 'model.template',
                                        listener: function(field, newValue, oldValue, scope, stopWatching) {
                                            if(newValue !== oldValue) {
                                                if(field.formControl){
                                                    field.formControl.$validate();
                                                }
                                            }
                                        }
                                    }
                                ],
                                controller: ['$scope', '$rootScope', 'DccumentsFactory', 'Language', 'CommonService', 'GlobalDataFactory', function($scope, $rootScope, DccumentsFactory, Language, CommonService, GlobalDataFactory) {
                                    if($scope.fields && $scope.fields.length > 0 && $scope.fields[0].templateOptions && $scope.fields[0].templateOptions.optionsAux){
                                    	DccumentsFactory.typeSelected({id: $scope.model.typeSelect}, $scope.model, undefined, undefined, $scope.fields[0].templateOptions.optionsAux);
                                    }else{
                                    	DccumentsFactory.typeSelected({id: $scope.model.typeSelect}, $scope.model);
                                    }
                                    DccumentsFactory.setFileArchiveClassification($scope.model);
                                    
                                    $scope.$on('modifySelectedFile', function(event, args) {
                                    	DccumentsFactory.selectFile($scope.model, args.element.files);
                                    });
                                }]
                            },
                            {
                                key: 'title',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.title',
                                    required: false,
                                    focus: false,
                                    placeholder:'',
                                    updateOnBlur: true
                                },
                                controller: ['$scope', 'DccumentsFactory', 'Language', '$rootScope', 'GlobalDataFactory', function($scope, DccumentsFactory, Language, $rootScope, GlobalDataFactory) {
                                    var titleAux = '';
                                    if($scope.model.typeSelect) {
                                        var selectedType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $scope.model.typeSelect);

                                        if(selectedType) {
                                            $scope.to.placeholder = selectedType[Language.getActiveColumn()];
                                        } else {
                                            $scope.to.placeholder = '';
                                        }
                                    } else {
                                        $scope.to.placeholder = '';
                                    }

                                    $scope.to.onFocus = function(viewValue){
                                        if($rootScope && ($rootScope.esetMode || ($rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.validate_title_document && $rootScope.app.configuration.validate_title_document.value))){
                                            if($scope.model.typeSelect) {
                                                var selectedType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $scope.model.typeSelect);
                                                if(selectedType) {                                                	
                                                	if ($scope.lastSelectedType) {
                                                		//Ya hay un tipo de documento seleccionado
                                                		var pos = $scope.model.title.indexOf($scope.lastSelectedType);
                                                		if (pos >= 0) {
                                                			//Encuentra el acronimo del tipo de documento anterior, lo reemplaza por el nuevo acronimo
                                                			$scope.model.title = $scope.model.title.replace($scope.lastSelectedType, selectedType.acronym);
                                                		} else {
                                                			//No encuentra el acronimo del tipo de documento anterior, pone el nuevo acronimo al principio
                                                			$scope.model.title = selectedType.acronym + ' ' + $scope.model.title;
                                                		}                                                		                                                		
                                                	} else {
                                                		//No hay un tipo de documento seleccionado
                                                		if (viewValue) {
                                                			if (viewValue.startsWith(selectedType.acronym + ' ')) {
                                                				$scope.model.title = viewValue;
                                                			} else {
                                                				$scope.model.title = selectedType.acronym + ' ' + viewValue;
                                                			}
                                                		} else {
                                                			$scope.model.title = selectedType.acronym + ' ';
	                                                    }
                                                	}                                                	
                                                	titleAux = angular.copy($scope.model.title);
                                                	$scope.lastSelectedType = selectedType.acronym;
                                                }                                                
                                            }
                                        }
                                    };

                                }],
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    if(scope.model.wizard_AllInOne) {
                                        return true;
                                    }else{
                                        return false;
                                    }
                                }
                            },
                            {
                                key: 'dateDocumentCreation',
                                type: 'annexaDatepickerRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.dateDocumentCreation',
                                    required: true,
                                    validate: true,
                                    changeFunction: 'to.changeFunction',
                                    datepickerOptions: {
                                        format: 'dd/MM/yyyy',
                                        initDate: new Date(),
                                        showWeeks: false,
                                        startingDay: 1
                                    }
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    return !scope.model.add_document_type_and_creation_date_to_document_name;
                                }
                            },
                            {
                                key: 'archiveClassification',
                                type: 'annexaSelectTreeRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    label: 'global.literals.classificationBox',
                                    options: [],
                                    required: false,
                                    focus: false
                                },
                                controller:['$scope', 'CommonService', 'DccumentsFactory', 'Language', 'GlobalDataFactory', function($scope, CommonService, DccumentsFactory, Language, GlobalDataFactory) {
                                    $scope.to.options = CommonService.getTreeData(GlobalDataFactory.archiveClassifications, Language.getActiveColumn());
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.onSelected = function(index) {
                                        $scope.model.modifyArchiveClassification = true;
                                    };
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model.typeSelect',
                                    listener: function(field, _new, aux,scope) {
                                        var DccumentsFactory = angular.element(document.body).injector().get('DccumentsFactory');
                                        DccumentsFactory.setFileArchiveClassification(scope.model);
                                    }
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    var DccumentsFactory = angular.element(document.body).injector().get('DccumentsFactory');
                                    return DccumentsFactory.hideArchiveClassification(scope.model);
                                }
                            },
                            {
                                key: 'section',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.section',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    clearHide: true
                                },
                                controller: ['$scope', 'Language', 'GlobalDataFactory', 'DccumentsFactory', function($scope, Language, GlobalDataFactory, DccumentsFactory) {
                                    $scope.to.options = DccumentsFactory.sectionsUser;
                                    $scope.to.labelProp = Language.getActiveColumn();
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    return scope.model.oneSection ? true : false;
                                }
                            },
                            {
                                key: 'profiles',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.profiles',
                                    valueProp: 'id',
                                    labelProp: 'completeName',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller:['$scope', 'Language', 'GlobalDataFactory', 'DccumentsFactory', 'HelperService', function($scope, Language, GlobalDataFactory, DccumentsFactory, HelperService) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = $scope.model.module != 'EXPED' ? DccumentsFactory.profilesUser :
                                        $linq(DccumentsFactory.profilesUser).intersect($linq(JSOG.decode($scope.model.extra).processProfiles).select("x => x.profile").toArray(), "(x, y) => x.id == y.id").toArray();
                                    
                                    $scope.to.options = $linq($scope.to.options).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                                    
                                    if($scope.to.options && $scope.to.options.length == 1){
                                        if($scope.model.profiles){
                                            $scope.model.profiles.push($scope.to.options[0].id);
                                        }
                                        else{
                                            $scope.model.profiles = [];
                                            $scope.model.profiles.push($scope.to.options[0].id);
                                        }
                                    }else if($scope.model.module == 'EXPED'){
                                        if(!$scope.model.profiles){
                                            $scope.model.profiles = [];
                                        }else{
                                            $scope.model.profiles.length = 0;
                                        }
                                    }
                                }],
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    return scope.model.oneProfile ? true : false;
                                }

                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        generateFoliate: {
            title: 'global.literals.generate_foliate',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'selectAll',
                                type: 'annexaSelectAllRow',
                                className: 'col-sm-12 p-t-0 p-b-0',
                                templateOptions: {
                                    type: 'checkbox',
                                    label: 'global.bpm.selectAllDocs',
                                    validate: false,
                                    required: false,
                                    changeValue: function(){}
                                },
                                controller:['$scope', function($scope) {
                                    $scope.to.changeValue = function(){
                                        if($scope.model.documents){
                                        	var keys = Object.keys($scope.model.documents);
                                            if(keys && keys.length > 0){
	                                            _.forEach(keys, function(key){
	                                                if($scope.model.selectAll){
	                                                    $scope.model.documents[key] = true;
	                                                }else{
	                                                    $scope.model.documents[key] = false;
	                                                };
	                                            });
	                                        }else{
	                                        	$scope.model.selectAll = false;
	                                        }
                                        }else{
                                        	$scope.model.selectAll = false;
                                        }
                                    };
                                }],
                                hideExpression: function($viewValue, $modelValue, scope) {
                                    var keys = Object.keys(scope.model.documents);
                                    if(keys && keys.length < 2){
                                        return true;
                                    }
                                    return false;
                                }
                            },
                            {
                                key: 'selectAllDocuments',
                                type: 'annexaSelectAllRow',
                                className: 'col-sm-3 p-t-0 p-b-0',
                                templateOptions: {
                                    type: 'checkbox',
                                    label: 'global.bpm.selectAllDocuments',
                                    validate: false,
                                    required: false,
                                    changeValue: function(){}
                                },
                                controller:['$scope', function($scope) {
                                    $scope.to.changeValue = function(){
                                        if($scope.model.documents){
                                        	var keys = $linq($scope.fields[4].templateOptions.options).where("x => x.type == 'DOCUMENT'").select("x => x.id").toArray();
                                            if(keys && keys.length > 0){
	                                            _.forEach(keys, function(key){
	                                                if($scope.model.selectAllDocuments){
	                                                    $scope.model.documents[key] = true;
	                                                }else{
	                                                    $scope.model.documents[key] = false;
	                                                };
	                                            });
	                                        }else{
	                                        	$scope.model.selectAllDocuments = false;
	                                        }
                                        }else{
                                        	$scope.model.selectAllDocuments = false;
                                        }
                                    };
                                }],
                                hideExpression: function($viewValue, $modelValue, scope) {
                                    var keys = Object.keys(scope.model.documents);
                                    if(keys && keys.length < 2){
                                        return true;
                                    }
                                    return false;
                                }
                            },
                            {
                                key: 'selectAllWorkDocuments',
                                type: 'annexaSelectAllRow',
                                className: 'col-sm-5 p-t-0 p-b-0',
                                templateOptions: {
                                    type: 'checkbox',
                                    label: 'global.bpm.selectAllWorkDocuments',
                                    validate: false,
                                    required: false,
                                    changeValue: function(){}
                                },
                                controller:['$scope', function($scope) {
                                    $scope.to.changeValue = function(){
                                        if($scope.model.documents){
                                        	var keys = $linq($scope.fields[4].templateOptions.options).where("x => x.type == 'WORK_DOCUMENT'").select("x => x.id").toArray();
                                            if(keys && keys.length > 0){
	                                            _.forEach(keys, function(key){
	                                                if($scope.model.selectAllWorkDocuments){
	                                                    $scope.model.documents[key] = true;
	                                                }else{
	                                                    $scope.model.documents[key] = false;
	                                                };
	                                            });
	                                        }else{
	                                        	$scope.model.selectAllWorkDocuments = false;
	                                        }
                                        }else{
                                        	$scope.model.selectAllWorkDocuments = false;
                                        }
                                    };
                                }],
                                hideExpression: function($viewValue, $modelValue, scope) {
                                    var keys = Object.keys(scope.model.documents);
                                    if(keys && keys.length < 2){
                                        return true;
                                    }
                                    return false;
                                }
                            },
                            {
                                key: 'selectAllInternalDocument',
                                type: 'annexaSelectAllRow',
                                className: 'col-sm-4 p-t-0 p-b-0',
                                templateOptions: {
                                    type: 'checkbox',
                                    label: 'global.bpm.selectAllInternalDocument',
                                    validate: false,
                                    required: false,
                                    changeValue: function(){}
                                },
                                controller:['$scope', function($scope) {
                                    $scope.to.changeValue = function(){
                                        if($scope.model.documents){
                                        	var keys = $linq($scope.fields[4].templateOptions.options).where("x => x.type == 'INTERNAL_DOCUMENT'").select("x => x.id").toArray();
                                            if(keys && keys.length > 0){
		                                    	_.forEach(keys, function(key){
		                                            if($scope.model.selectAllInternalDocument){
		                                                $scope.model.documents[key] = true;
		                                            }else{
		                                                $scope.model.documents[key] = false;
		                                            };
		                                        });
                                            }else{
                                            	$scope.model.selectAllInternalDocument = false;
                                            }
                                        }else{
                                        	$scope.model.selectAllInternalDocument = false;
                                        }
                                    };
                                }],
                                hideExpression: function($viewValue, $modelValue, scope) {
                                    var keys = Object.keys(scope.model.documents);
                                    if(keys && keys.length < 2){
                                        return true;
                                    }
                                    return false;
                                }
                            },
                            {
                                key: 'documents',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed: true,
                                    row: true
                                },
                                templateOptions: {
                                    type: 'checkHTMLOneModel',
                                    optionsAttr: 'bs-options',
                                    required: false,
                                    validate: false,
                                    options: [],
                                    ngOptions: 'option[to.valueProp] as option in to.options',
                                    valueProp: 'id',
                                    labelProp: 'value',
                                    labelHtml: 'typeName'
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model.documents',
                                    listener: function (field, _new, _old, scope) {
                                        var keys = Object.keys(_new);
                                        var isAllTrue = true;
                                        if (keys && keys.length > 0) {
                                            _.forEach(keys, function (key) {
                                                isAllTrue = isAllTrue && (_new[key]) ? true : false;
                                            });
                                        } else {
                                            isAllTrue = false;
                                        }
                                        scope.model.selectAll = isAllTrue;
                                        keys = $linq(scope.fields[4].templateOptions.options).where("x => x.type == 'DOCUMENT'").select("x => x.id").toArray();
                                        var isAllDocument = true;
                                        if (keys && keys.length > 0) {
                                            _.forEach(keys, function (key) {
                                            	isAllDocument = isAllDocument && (_new[key]) ? true : false;
                                            });
                                        } else {
                                        	isAllDocument = false;
                                        }
                                        scope.model.selectAllDocuments = isAllDocument;
                                        keys = $linq(scope.fields[4].templateOptions.options).where("x => x.type == 'WORK_DOCUMENT'").select("x => x.id").toArray();
                                        var isAllWorkDocument = true;
                                        if (keys && keys.length > 0) {
                                            _.forEach(keys, function (key) {
                                            	isAllWorkDocument = isAllWorkDocument && (_new[key]) ? true : false;
                                            });
                                        } else {
                                        	isAllWorkDocument = false;
                                        }
                                        scope.model.selectAllWorkDocuments = isAllWorkDocument;
                                        keys = $linq(scope.fields[4].templateOptions.options).where("x => x.type == 'INTERNAL_DOCUMENT'").select("x => x.id").toArray();
                                        var isAllInternalDocument = true;
                                        if (keys && keys.length > 0) {
                                            _.forEach(keys, function (key) {
                                            	isAllInternalDocument = isAllInternalDocument && (_new[key]) ? true : false;
                                            });
                                        } else {
                                        	isAllInternalDocument = false;
                                        }
                                        scope.model.selectAllInternalDocument = isAllInternalDocument; 
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        notificationThirdChangeStateModify: {
            title: 'global.literals.changeStateModify',
            size: '',
            icon: 'fa fa-check-square-o',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-label-value label="global.literals.state" value="modal.data.status[modal.languageColumn]"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field child-class="m-x-0" is-read-only="true" type="date" label="global.literals.date" model-value="modal.data.createdDate" model-property="createdDate" save="modal.saveChangeStateModify(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field child-class="m-x-0" is-read-only="true" type="time" label="global.literals.time" model-value="modal.data.createdTime" model-property="createdTime" save="modal.saveChangeStateModify(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field child-class="m-x-0" is-read-only="true" type="textarea" label="global.literals.observations" model-value="modal.data.observations" model-property="observations" save="modal.saveChangeStateModify(val, prop)" required="true" blur="ignore" maxlength="500" rows="3"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-label-value label="global.literals.evidences" type="base64FileList" value="modal.data.evidences" value-property="name" delete-func="modal.deleteEvidence"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <div class="row">',
                '           <div class="col-xs-12">',
                '               <span class="btn btn-file btn-sm grey text-white">',
                '                   <span><i class="fa fa-plus" aria-hidden="true"></i> {{\'global.literals.addEvidences\' | translate}}</span>',
                '                   <input type="file" multiple class="form-control" id="newEvidences" name="newEvidences" onchange="angular.element(this).scope().modal.addEvidences(this)">',
                '               </span>',
                '           </div>',
                '       </div>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        notificationThirdChangeState: {
            title: 'global.literals.changeState',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'notificationThird',
                                type: 'annexaHidden',
                                className: '',
                                templateOptions: {
                                    type: 'hidden',
                                    label: '',
                                    required: false
                                }
                            },
                            {
                                key: 'notificationType',
                                type: 'annexaHidden',
                                className: '',
                                templateOptions: {
                                    type: 'hidden',
                                    label: '',
                                    required: false
                                }
                            },
                            {
                                key: 'status',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    required: true,
                                    focus: true,
                                    label: 'global.literals.state',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: []
                                },
                                controller: ['$scope', 'Language', function($scope, Language) {
                                    $scope.to.labelProp = Language.getActiveColumn();

                                    var usedStatuses = $linq($scope.model.notificationThird.statuses).select("x => x.status").toArray();

                                    var allowedStatuses = $linq($scope.model.notificationType.statuses).except(usedStatuses, "(x,y) => x.id == y.id").orderBy("x => x.orderView").toArray();

                                    $scope.to.options = allowedStatuses;
                                }],
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'createdDate',
                                type: 'annexaDatepickerRow',
                                className: 'col-sm-3',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.date',
                                    required: true,
                                    validate: true,
                                    changeFunction: 'to.changeFunction',
                                    datepickerOptions: {
                                        format: 'dd/MM/yyyy',
                                        initDate: new Date(),
                                        showWeeks: false,
                                        startingDay: 1,
                                        maxDate: new Date()
                                    }
                                }
                            },
                            {
                                key: 'createdTime',
                                type: 'annexaTimepickerRow',
                                className: 'col-sm-3',
                                templateOptions: {
                                    label: 'global.literals.time',
                                    required: true,
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ],
                        wrapper: 'annexaRow'
                    },
                    {
                        key: 'row2',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'observations',
                                type: 'annexaTextAreaRow',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.observations',
                                    required: false
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }

                        ]
                    },
                    {
                        key: 'row3',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'thirds',
                                type: 'annexaComponent',
                                templateOptions: {
                                    type: 'annexa-label-value'
                                },
                                data: {
                                    title: 'global.literals.evidences',
                                    value: [],
                                    type: 'base64FileList',
                                    rowClass: 'm-b-xs row',
                                    labelStrong: true
                                }
                            },
                            {
                                key: 'evidences',
                                type: 'annexaFileUploadBase64',
                                templateOptions: {
                                    label: 'global.literals.addEvidences',
                                    required: false
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    accept: '',
                                    button: true
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        notificationsByAddresses: {
            title: 'global.literals.createNewNotifications',
            size: '',
            icon: 'fa fa-check-square-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'addresses',
                                type: 'annexaComponent',
                                templateOptions: {
                                    type: 'annexa-notification-addresses'
                                },
                                data: {
                                    addresses: [],
                                    notificationTypes: []
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        notificationNew: {
            title: 'global.literals.new_notification_title',
            size: '',
            icon: 'fa fa-check-square-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'row2',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'thirds',
                                type: 'annexaComponent',
                                templateOptions: {
                                    type: 'annexa-select-thirds'
                                },
                                data: {
                                    title: 'global.literals.receivers',
                                    thirds: [],
                                    dossier: undefined
                                }
                            }
                        ]
                    },
                    {
                        key: 'row3',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'documents',
                                type: 'annexaComponent',
                                templateOptions: {
                                    type: 'annexa-select-documents'
                                },
                                data: {
                                    title: 'global.literals.documents',
                                    documents: []
                                }
                            }
                        ]
                    },
                    {
                        key: 'row4',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'extract',
                                type: 'annexaTextAreaRow',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.reason',
                                    required: true
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        notificationModify: {
            title: 'global.literals.edit_notification_title',
            size: 'modal-lg',
            icon: 'fa fa-check-square-o',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-4">',
                '       <annexa-label-value label="global.literals.type" value="modal.data.notificationType[modal.languageColumn]"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-8">',
                '       <annexa-label-value label="global.literals.reason" value="modal.data.extract"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-label-value label="global.literals.documents" type="documentList" value="modal.data.documents" origin="NOTIFICATION"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-12" ng-if="!modal.data.id">',
                '       <annexa-label-value label="global.literals.receivers" type="thirdList" value="modal.receivers"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-12" ng-if="modal.data.id">',
                '       <annexa-notification-statuses-evidences notification="modal.data"></annexa-notification-statuses-evidences>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        publishDocument: {
            title: 'global.literals.publish',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <annexa-document-publish document="modal.data.document" submit-function="modal.submitFunction" dossier-id="{{modal.originId}}"></annexa-document-publish>',
                '</div>'
            ],
            alerts: []
        },
        publishDocuments: {
            title: 'global.literals.publish',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <annexa-documents-publish documents="modal.data.documents" submit-function="modal.submitFunction" dossier-id="{{modal.originId}}"></annexa-documents-publish>',
                '</div>'
            ],
            alerts: []
        },
        modifyPublishDocument:{
        	 title: 'global.literals.publish',
             size: 'modal-sm',
             annexaFormly: {
                 fields: [
                     {
                         key: 'modal_body',
                         className: 'modal-body p-lg',
                         fieldGroup: [
                             {
                                 key: 'endPublishDate',
                                 type: 'annexaDatepickerRow',
                                 className: 'col-sm-12',
                                 templateOptions: {
                                     type: 'text',
                                     label: 'global.publish.endPublishDate',
                                     required: false,
                                     validate: true,
                                     changeFunction: 'to.changeFunction',
                                     datepickerOptions: {
                                         format: 'dd/MM/yyyy',
                                         initDate: new Date(),
                                         showWeeks: false,
                                         startingDay: 1
                                     }
                                 }
                             },
                             {
                                 key: 'favorite',
                                 type: 'annexaRadioCheckboxRow',
                                 className: 'col-sm-12',
                                 data: {
                                     row: true,
                                     informed: true,
                                     clear: function($event,model,key, $select) {
                                         $event.stopPropagation();
                                         model[key] = undefined;
                                         if($select) {
                                             $select.selected = undefined;
                                             $select.search = undefined;
                                         }
                                     }
                                 },
                                 templateOptions: {
                                     type: 'checkbox',
                                     radioClass: 'radio-inline',
                                     optionsAttr: 'bs-options',
                                     required: false,
                                     validate: false,
                                     ngOptions:'option[to.valueProp] as option in to.options',
                                     valueProp: 'id',
                                     labelProp: 'value',
                                     options:[]
                                 },
                                 controller: function ($scope, $filter) {
                                     $scope.to.options = [];
                                     $scope.to.options.push({id:true, value:$filter('translate')('global.publish.favorite')});
                                 }
                             }
                         ]
                     }
                 ]
             },
             submitModal: function () {
             }
        },
        createDiligencePublish:{
       	 title: 'global.literals.generateDiligence',
            size: 'modal-sm',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'diligenciaIdioma',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    focus: false,
                                    label: 'global.literals.language',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: []
                                },
                                controller: ['$scope', '$rootScope', 'Language', function($scope, $rootScope, Language) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = [];
                                    $scope.to.options.push({"id":"ca", "language1":"Català", "language2":"Catalán", "language3":"Catalan"});
                                    $scope.to.options.push({"id":"es", "language1":"Castellà", "language2":"Castellano", "language3":"Castelhan"});
                                    $scope.to.options.push({"id":"oc", "language1":"Aranès", "language2":"Aranes", "language3":"Aranés"});
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'diligenciaFormat',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    focus: false,
                                    label: 'global.literals.diligenceType',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: []
                                },
                                controller: ['$scope', '$rootScope', 'Language', function($scope, $rootScope, Language) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = [];
                                    $scope.to.options.push({"id":"imprimible", "language1":"PDF", "language2":"PDF", "language3":"PDF"});
                                    $scope.to.options.push({"id":"electronica", "language1":"XML", "language2":"XML", "language3":"XML"});
                                }],  
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
       },
       notificationModify1: {
            title: 'global.literals.edit_notification_title',
            size: '',
            icon: 'fa fa-check-square-o',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field is-read-only="{{modal.data.notificationStatus.id == \'PENDING_SEND\' || modal.data.notificationStatus == \'PENDING_SEND\'}}" type="select" label="global.literals.type" model-value="modal.data.notificationType" model-property="notificationType" save="modal.updateNotification(val, prop)" required="true" blur="ignore" lang-col="modal.language" select-options="modal.notificationTypes"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field is-read-only="{{modal.data.notificationStatus.id == \'PENDING_SEND\' || modal.data.notificationStatus == \'PENDING_SEND\' || modal.data.notificationStatus.id == \'SEND\' || modal.data.notificationStatus == \'SEND\'}}" type="select" label="global.literals.state" model-value="modal.data.notificationStatus" model-property="notificationStatus" save="modal.updateNotification(val, prop)" required="true" blur="ignore" lang-col="modal.language" select-options="modal.notificationStatuses"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field is-read-only="{{modal.data.notificationStatus.id == \'PENDING_SEND\' || modal.data.notificationStatus == \'PENDING_SEND\'}}" type="annexa-checkbox-list" label="global.literals.receivers" model-value="modal.receivers" model-property="receivers" save="modal.updateNotification(val, prop)" required="false" blur="ignore" label-col="modal.languageRec" print-function="modal.printReceivers"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row m-b-sm">',
                '   <div class="col-sm-12">       ',
                '       <label class=" small m-b-0">',
                '           <span class="ng-scope ng-binding" translate="global.literals.documents">Documents</span>',
                '       </label>',
                '   </div>',
                '</div>',
                '<div class="row  m-b-sm">',
                '   <div class="col-lg-12" data-ng-repeat="document in modal.documents" ng-if="document.checked || modal.data.notificationStatus.id == \'PENDING_SEND\' || modal.data.notificationStatus == \'PENDING_SEND\'">',
                '       <input type="checkbox" ng-if="modal.data.notificationStatus.id == \'PENDING_SEND\' || modal.data.notificationStatus == \'PENDING_SEND\'" class="m-r-0" ng-model="document.checked"  ng-change="modal.updateNotification(document, \'documents\')" ng-checked="document.checked" id="{{document + \'_\' + $index}}">',
                '       <a href ng-click="modal.viewOrDownloadDocument(document.currentDocument, document.fileName, document.currentFileType);" class="text-primary _400 small-lg "><span class="_600">{{document.name}}</span></a> ',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field is-read-only="{{modal.data.notificationStatus.id == \'PENDING_SEND\' || modal.data.notificationStatus == \'PENDING_SEND\'}}" type="textarea" label="global.literals.reason" model-value="modal.data.extract" model-property="extract" save="modal.updateNotification(val, prop)" blur="ignore" maxlength="500" rows="3"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row m-b-sm" ng-hide="modal.data.notificationStatus.id == \'PENDING_SEND\' || modal.data.notificationStatus == \'PENDING_SEND\'">',
                '   <div class="col-sm-9">       ',
                '       <label class=" small m-b-0">',
                '           <span class="ng-scope ng-binding" translate="global.literals.evidences">Evidencies</span>',
                '       </label>',
                '   </div>',
                '   <div class="col-sm-3">       ',
                '       <button type="button" ng-click="modal.addEvidenceModal(modal.data, modal)" class="btn btn-sm grey inline">',
                '           <span translate="global.literals.newEvidence" class="ng-scope">New Evidence</span>',
                '       </button>',
                '   </div>',
                '</div>',
                '<div class="row  m-b-sm" data-ng-repeat="evidence in modal.data.evidences">',
                '   <div class="col-lg-12">',
                '       <a href ng-click="modal.viewOrDownloadDocument(evidence.evidence, evidence.name);" class="text-primary _400 small-lg "><span class="_600">{{\'global.literals.evidence\' | translate}} {{evidence.name}}</span></a> ',
                '   </div>',
                '</div>',
            ],
            submitModal: function () {
            }
        }, selectMultipleCheckbox: {
            title: '',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'selectAll',
                                type: 'annexaSelectAllRow',
                                className: 'col-sm-12 p-t-0 p-b-0',
                                templateOptions: {
                                    type: 'checkbox',
                                    label: 'global.literals.selectAll',
                                    validate: false,
                                    required: false,
                                    changeValue: function(){}
                                },
                                controller:['$scope', function($scope) {
                                    $scope.to.changeValue = function(){
                                        if($scope.model.selectValues){
                                        	var keys = Object.keys($scope.model.selectValues);
                                            if(keys && keys.length > 0){
	                                            _.forEach(keys, function(key){
	                                                if($scope.model.selectAll){
	                                                    $scope.model.selectValues[key] = true;
	                                                }else{
	                                                    $scope.model.selectValues[key] = false;
	                                                };
	                                            });
	                                        }else{
	                                        	$scope.model.selectAll = false;
	                                        }
                                        }else{
                                        	$scope.model.selectAll = false;
                                        }
                                    };
                                }],
                                hideExpression: function($viewValue, $modelValue, scope) {
                                    var keys = Object.keys(scope.model.selectValues);
                                    if(keys && keys.length < 2){
                                        return true;
                                    }
                                    return false;
                                }
                            },
                            {
                                key: 'selectValues',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed: true,
                                    row: true
                                },
                                templateOptions: {
                                    type: 'checkHTMLOneModel',
                                    optionsAttr: 'bs-options',
                                    required: false,
                                    validate: false,
                                    options: [],
                                    ngOptions: 'option[to.valueProp] as option in to.options',
                                    valueProp: 'id',
                                    labelProp: 'value',
                                    labelHtml: 'typeName'
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model.selectValues',
                                    listener: function (field, _new, _old, scope) {
                                        var keys = Object.keys(_new);
                                        var isAllTrue = true;
                                        if (keys && keys.length > 0) {
                                            _.forEach(keys, function (key) {
                                                isAllTrue = isAllTrue && (_new[key]) ? true : false;
                                            });
                                        } else {
                                            isAllTrue = false;
                                        }
                                        scope.model.selectAll = isAllTrue;
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        }, editReceiverAndDocuments: {
            title: 'global.literals.edit_receiver_and_documents_modal_title',
            size: '',
            icon: 'fa fa-check-square-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'thirds',
                                fieldGroup: [
                                    {
                                        key: 'thirdsName',
                                        type: 'annexaLabel',
                                        templateOptions: {
                                            label: '',
                                            value:''
                                        },
                                        data: {
                                            informed: true,
                                            row: true,
                                            colClass: ' col-sm-12',
                                            labelClass: 'label-strong'
                                        }
                                    }
                                ]
                            },
                            {
                                key: 'documents',
                                fieldGroup: [
                                    {
                                        key: 'documentsName',
                                        type: 'annexaLabel',
                                        templateOptions: {
                                            label: '',
                                            value:''
                                        },
                                        data: {
                                            informed: true,
                                            row: true,
                                            colClass: ' col-sm-12',
                                            labelClass: 'label-strong'
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        createDossierTransactionPopup:{
            title: 'global.literals.createTram',
            size: 'modal-lg',
            icon: 'fa fa-check',
            submitModal: function () {
            },
            alerts: []
        },
        sendProposalDossierTransactionPopup:{
            title: 'global.tram.literals.sendProposal',
            size: 'modal-lg',
            icon: 'fa fa-send',
            submitModal: function () {
            },
            alerts: []
        },
        sendGuidedProposalDossierTransactionPopup:{
            title: 'global.tram.literals.sendProposal',
            size: 'modal-lg',
            icon: 'fa fa-send',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'nextDossierTransactionInfo',
                                type: 'annexaNextDossierTransactionInfoRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    options:{},
                                    transaction:{}
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        addFooterClaimPopup:{
            title: 'global.sec.literals.addFooterClaimPopup',
            size: '',
            icon: '&#xe02e',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'footerClaim',
                                type: 'annexaFilterInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    label: 'global.literals.DOSSIER',
                                    placeholder: 'global.literals.placeholder_search_dossiers',
                                    search: function() {},
                                    advancedSearch: function() {},
                                    advancedSearchAdd: function(object, modal) {
                                        var proccesResult = function (object) {
                                            return object.language1;
                                        };
                                        object.value = proccesResult(object.object);
                                        this.close();
                                    }
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        changePassword: {
            title: 'global.literals.changePassword',
            size: '',
            icon: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'oldPassword',
                                type: 'annexaInputRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    type: 'password',
                                    label: 'global.literals.oldPassword',
                                    required: true,
                                    focus: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'newPassword',
                                type: 'annexaInputRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    type: 'password',
                                    label: 'global.literals.newPassword',
                                    required: true,
                                    focus: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'rePassword',
                                type: 'annexaInputRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    type: 'password',
                                    label: 'global.literals.repassword',
                                    required: true,
                                    focus: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'labelPassword',
                                type: 'annexaLabel',
                                templateOptions: {
                                    label: '',
                                    value:''
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller: ['$scope','GlobalDataFactory', function($scope, GlobalDataFactory) {
                                	$scope.to.label = GlobalDataFactory.passwordAlertMessage;
                                }]
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        secDocFromTemplate: {
            title: 'global.literals.dossier_doc_from_template_title',
            size: 'modal-md',
            icon: 'fa fa-user',
            annexaFormly: {
                fields: [
                {
                    key: 'modal_body',
                    className: 'modal-body p-lg',
                    fieldGroup: [
                        {
                            key: 'docType',
                            type: 'annexaSelectRow',
                            className: 'col-sm-12',
                            templateOptions: {
                                required: true,
                                focus: true,
                                label: 'global.literals.type',
                                optionsAttr: 'bs-options',
                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                valueProp: 'id',
                                labelProp: 'language1',
                                placeholder: '',
                                options: [],
                                type: undefined,
                                onSelected: function($item){}
                            },
                            controller: ['$scope', '$rootScope', 'Language', 'GlobalDataFactory', 'CustomFieldFactory', function($scope, $rootScope, Language, GlobalDataFactory, CustomFieldFactory) {
                            	$scope.to.onSelected = function($item){
                            		$rootScope.$broadcast('AnnexaSECDocChangeType');  
                                };
                                $scope.to.labelProp = Language.getActiveColumn();
                                var documentType = $rootScope.app.configuration.sec_document_types[$scope.to.type];
                                if(documentType && documentType.id) {
                                	var options = [];
                                	_.forEach(documentType.id, function(id){
                                		if(GlobalDataFactory.allDocumentTypes){
                                			var docType = $linq(GlobalDataFactory.allDocumentTypes).where("x => x.id == "+id).toArray();
                                			if(docType && docType.length > 0){
                                				_.forEach(docType, function(dt){
                                					if(!_.contains(options, dt)){
                                						options.push(dt);
                                					}
                                				});
                                			}
                                		}
                                	});
                                	$scope.to.options = options;
                                	if($scope.to.options && $scope.to.options.length == 1){
                                		$scope.model.docType = $scope.to.options[0].id;
                                	}
                                	if($scope.to.options && $scope.to.options.length > 0){
                                		_.forEach($scope.to.options, function (type) {
                                            var custom_fields = $linq(type.customFields).orderBy("x => x.viewOrder").toArray();
                                            var fieldsCF = [];
                                            _.forEach(custom_fields, function (field) {
                                                var formCF = new AnnexaFormly();
                                                formCF = CustomFieldFactory.addFormlyFieldRow(formCF, field.customField, field.required, type.id + '_' + field.customField.id);
                                                formCF.fields[0].className = 'col-sm-12';
                                                formCF.fields[0].hideExpression = function ($viewValue, $modelValue, scope) {
                                                    if (!field.noEditable) {
                                                        if (!scope.model  || !scope.model.docType || !scope.model.docType || type.id != scope.model.docType) {
                                                            return true;
                                                        }
                                                    } else {
                                                        return true;
                                                    }
                                                }


                                                fieldsCF.push(formCF.fields[0]);
                                                $scope.model['cf_' + type.id + '_' + field.customField.id] = CustomFieldFactory.calculateValueCustomField(field, $scope.model, ('cf_' + type.id + '_' + field.customField.id));
                                            });
                                            $scope.fields.push.apply($scope.fields, fieldsCF);
                                        });
                                	}
                                	
                                }
                            }],
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            }
                        },
                        {
                            key: 'secTemplate',
                            type: 'annexaSelectRow',
                            className: 'col-sm-12',
                            templateOptions: {
                                label: 'global.literals.template',
                                optionsAttr: 'bs-options',
                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                options: [],
                                valueProp: 'id',
                                labelProp: 'language1',
                                placeholder: '',
                                required: true,
                                type: undefined,
                                organId: undefined
                            },
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            },
                            controller:['$scope', 'SecFactory', '$filter', 'CommonService', 'Language', '$rootScope','GlobalDataFactory',  function($scope, SecFactory, $filter, CommonService, Language, $rootScope,GlobalDataFactory) {
                            	$scope.to.labelProp = Language.getActiveColumn();
                            	var documentType = $rootScope.app.configuration.sec_document_types[$scope.to.type];
                                var templates = $linq(GlobalDataFactory.secTemplates).where(function(x){
                                	if(documentType && documentType.id && documentType.id.length > 0 && x.documentType && x.documentType.id){
                                		if(_.contains(documentType.id, x.documentType.id+"") && $scope.to.type == x.type){
                                			if(!$scope.to.organId || !x.organs || x.organs.length == 0 || ($scope.to.organId && x.organs.length > 0 && $linq(x.organs).contains($scope.to.organId, "(x, y) => x.id == y"))){
                                				if(x.profiles && x.profiles.length > 0){
                                                    var profiles = $linq(x.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
                                                        if(y.profile){
                                                            return (x.profile.id == y.profile.id);
                                                        }
                                                        else{
                                                            return true;
                                                        }
                                                    }).toArray();
                                                    if(profiles && profiles.length > 0){
                                                       return true;
                                                    } else {
                                                    	return false;
                                                    }
                                                }
                                				return true;
                                			}
                                		}
                                	}
                                	return false;
                                }).toArray();

                                $scope.to.options = ((templates && templates.length > 0)?templates:[]);
                                if($scope.to.options && $scope.to.options.length == 1){
                            		$scope.model.secTemplate = $scope.to.options[0].id;
                            	}
                            }]
                        },
                        {
                            key: 'documentTitle',
                            type: 'annexaInputRow',
                            className: 'col-sm-12',
                            templateOptions: {
                                type: 'text',
                                label: 'global.literals.title',
                                required: true,
                                focus: false,
                                sessionDate:undefined,
                                acronymOrgan: undefined
                            },
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            },
                            controller: ['$scope', 'SecFactory', '$filter', 'CommonService', 'Language', '$rootScope', 'GlobalDataFactory',  function($scope, SecFactory, $filter, CommonService, Language, $rootScope, GlobalDataFactory) {
                            	var labelProp = Language.getActiveColumn();
                            	$scope.changeFunctionTitle = function(viewValue){
                            		if($rootScope && ($rootScope.esetMode || ($rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.validate_title_document && $rootScope.app.configuration.validate_title_document.value))){
                                        if($scope.model.docType) {
                                            var selectedType = $linq(GlobalDataFactory.allDocumentTypes).singleOrDefault(undefined, "x => x.id == " + $scope.model.docType);
                                            if(selectedType) {
                                            	if ($scope.lastSelectedType) {
                                            		//Ya hay un tipo de documento seleccionado
                                            		if(!$scope.model.documentTitle){
                                            			$scope.model.documentTitle = "";
                                            		}
                                            		var pos = $scope.model.documentTitle.indexOf($scope.lastSelectedType);
                                            		if (pos == 0) {
                                            			//Encuentra el acronimo del tipo de documento anterior, lo reemplaza por el nuevo acronimo
                                            			$scope.model.documentTitle = $scope.model.documentTitle.replace($scope.lastSelectedType, (selectedType.acronym + ' '+selectedType[labelProp]));
                                            		} else {
                                            			//No encuentra el acronimo del tipo de documento anterior, pone el nuevo acronimo al principio
                                            			$scope.model.documentTitle = selectedType.acronym + ' '+selectedType[labelProp] + ' ' + $scope.model.documentTitle;
                                            		}                                                		                                                		
                                            	} else {
                                            		//No hay un tipo de documento seleccionado
                                            		if (viewValue) {
                                            			if (viewValue.startsWith(selectedType.acronym + ' '+selectedType[labelProp])) {
                                            				$scope.model.documentTitle = viewValue;
                                            			} else {
                                            				$scope.model.documentTitle = selectedType.acronym + ' '+selectedType[labelProp] + ' ' + viewValue;
                                            			}
                                            		} else {
                                            			$scope.model.documentTitle = selectedType.acronym + ' '+selectedType[labelProp]+(($scope.to.acronymOrgan)?' '+$scope.to.acronymOrgan:'')+(($scope.to.sessionDate)?' '+$filter('date')(new Date($scope.to.sessionDate), 'ddMMyyyy'):'');
                                                    }
                                            	}  
                                            	$scope.lastSelectedType = selectedType.acronym + ' '+selectedType[labelProp];
                                            }                                                
                                        }
                                    }else{
                                    	if($scope.model.docType) {
	                                    	var selectedType = $linq(GlobalDataFactory.allDocumentTypes).singleOrDefault(undefined, "x => x.id == " + $scope.model.docType);
	                                        if(selectedType) {   
	                                        	if ($scope.lastSelectedType) {
                                            		//Ya hay un tipo de documento seleccionado
                                            		if(!$scope.model.documentTitle){
                                            			$scope.model.documentTitle = "";
                                            		}
                                            		var pos = $scope.model.documentTitle.indexOf($scope.lastSelectedType);
                                            		if (pos == 0) {
                                            			//Encuentra el acronimo del tipo de documento anterior, lo reemplaza por el nuevo acronimo
                                            			$scope.model.documentTitle = $scope.model.documentTitle.replace($scope.lastSelectedType, selectedType[labelProp]);
                                            		} else {
                                            			//No encuentra el acronimo del tipo de documento anterior, pone el nuevo acronimo al principio
                                            			$scope.model.documentTitle =  selectedType[labelProp] + ' ' + $scope.model.documentTitle;
                                            		}                                                		                                                		
                                            	} else {
                                            		//No hay un tipo de documento seleccionado
                                            		if (viewValue) {
                                            			if (viewValue.startsWith(selectedType[labelProp] + ' ')) {
                                            				$scope.model.documentTitle = viewValue;
                                            			} else {
                                            				$scope.model.documentTitle = selectedType[labelProp] + ' ' + viewValue;
                                            			}
                                            		} else {
                                            			$scope.model.documentTitle = selectedType[labelProp]+(($scope.to.acronymOrgan)?' '+$scope.to.acronymOrgan:'')+(($scope.to.sessionDate)?' '+$filter('date')(new Date($scope.to.sessionDate), 'dd-MM-yyyy'):'');
                                                    }
                                            	}  
                                            	$scope.lastSelectedType = selectedType[labelProp];
	                                        }
                                    	}
                                    }
                                };
                                $scope.changeFunctionTitle();
                                $scope.to.onFocus = function(viewValue){
                                	$scope.changeFunctionTitle(viewValue);
                                }
                                $scope.$on('AnnexaSECDocChangeType', function(event, args){
                                	$scope.changeFunctionTitle();
                                });
                            }]
                        },
                        {
                            key: 'section',
                            type: 'annexaSelectRow',
                            className: 'col-sm-12',
                            templateOptions: {
                                required: true,
                                focus: false,
                                label: 'global.literals.section',
                                optionsAttr: 'bs-options',
                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                valueProp: 'id',
                                labelProp: 'language1',
                                placeholder: '',
                                options: []
                            },
                            controller: ['$scope', '$rootScope', 'Language', function($scope, $rootScope, Language) {
                                $scope.to.labelProp = Language.getActiveColumn();
                                $scope.to.options = $linq($rootScope.LoggedUser.userSections).distinctBy("x => x.section.id").select("x => x.section").toArray();
                                if($scope.to.options && $scope.to.options.length == 1){
                            		$scope.model.section = $scope.to.options[0].id;
                            	}
                            }],
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            },
                            hideExpression: function ($viewValue, $modelValue, scope) {
                                var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                var sections = $linq($rootScope.LoggedUser.userSections).distinctBy("x => x.section.id").select("x => x.section").toArray();

                                if(sections.length == 1){
                                	scope.model.section = sections[0].id;
                                    return true;
                                }

                                return false;
                            }
                        },
                        {
                            key: 'profile',
                            type: 'annexaSelectRow',
                            className: 'col-sm-12',
                            templateOptions: {
                                required: true,
                                focus: true,
                                label: 'global.literals.profile',
                                optionsAttr: 'bs-options',
                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                valueProp: 'id',
                                labelProp: 'language1',
                                placeholder: '',
                                options: []
                            },
                            controller: ['$scope', '$rootScope', 'Language', 'GlobalDataFactory', function($scope, $rootScope, Language, GlobalDataFactory) {
                                $scope.to.labelProp = Language.getActiveColumn();
                                $scope.to.options = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();
                                if($scope.to.options && $scope.to.options.length == 1){
                            		$scope.model.profile = $scope.to.options[0].id;
                            	}else if($scope.to.options && $scope.to.options.length > 1){
                            		var profiles = $linq($rootScope.LoggedUser.userDefaultEntityProfiles).where("x => x.entityType == 'SEC'").select("x => x.profile").toArray();
                                    if (profiles && profiles.length > 0) {
                                    	$scope.model.profile = profiles[0].id;
                                    }
                            	}
                            }],
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            },
                            hideExpression: function ($viewValue, $modelValue, scope) {
                                var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                var profiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();

                                if(profiles.length == 1){
                                	scope.model.profile = profiles[0].id;
                                    return true;
                                }

                                return false;
                            }
                        },
                        {
                            key: 'archiveClassification',
                            type: 'annexaSelectTreeRow',
                            className: 'col-sm-12',
                            templateOptions: {
                                label: 'global.literals.classificationBox',
                                options: [],
                                required: false,
                                focus: false
                            },
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            }
                        }
                    ]
                }]
            },
            alerts: [],
            submitModal: function () {}
        },newInteroperableWizardModal: {
            title: 'global.literals.newInteroperable',
            size: '',
            icon: '&#xe89c',
            wizard: {
                modal: undefined,
                finishWizard: function () {
                    if(this.modal) {
                        this.modal.submitModal();
                    }
                },
                exitValidation: function(form) {
                    return form && !form.$invalid;
                },
                steps: [
                    {
                        title: 'global.literals.newInteroperableWizardStep1',
                        key: 'selectServiceModality',
                        disabled: false,
                        disableNext: function(model, wizard) {
                            var selected = false;
                            if(model.service != undefined && model.modality != undefined && model.selectedThird != undefined) {
                            	selected = true;
                            }
                            return !selected;
                        },
                        beforeNext: function (step, wizard) {
                        	wizard.modal.documentFactory.getModalityAndDataFromService(wizard.steps[0].annexaFormly.model.service,wizard.steps[0].annexaFormly.model.modality)
                        		.then(function (data) {
                        			wizard.steps[1].annexaFormly.model.hasError = false;
                        			wizard.steps[1].title = wizard.steps[0].annexaFormly.model.serviceLabel + ' - ' + wizard.steps[0].annexaFormly.model.modalityLabel;
                        			wizard.steps[2].title = wizard.steps[0].annexaFormly.model.serviceLabel + ' - ' + wizard.steps[0].annexaFormly.model.modalityLabel;
                        			wizard.steps[1].annexaFormly.model = data;
                        			wizard.steps[1].annexaFormly.model.voGenericData = {};
                        			wizard.steps[1].annexaFormly.model.citizenFullName = wizard.steps[0].annexaFormly.model.selectedThird.completeName;
                        			wizard.steps[1].annexaFormly.model.citizenNIF = wizard.steps[0].annexaFormly.model.selectedThird.identificationDocument;
                        			wizard.steps[1].annexaFormly.model.citizenNIFType = wizard.steps[0].annexaFormly.model.selectedThird.identificationDocumentType;
                        			wizard.steps[1].annexaFormly.model.citizenName = wizard.steps[0].annexaFormly.model.selectedThird.name;
                        			wizard.steps[1].annexaFormly.model.citizenSecondSurname = wizard.steps[0].annexaFormly.model.selectedThird.surename2;
                        			wizard.steps[1].annexaFormly.model.citizenSurname = wizard.steps[0].annexaFormly.model.selectedThird.surename1;
                        				
                        			wizard.steps[1].annexaFormly.fields.splice(7);
                        			var fieldPos = 7;
                        			wizard.steps[1].annexaFormly.model.voModalityDataList = data.voModalityDataList;
                        			var dataListNoGroup = $linq(data.voModalityDataList).where("x => x.chooseGroup == null").toArray();
                        			if(data.voModalityDataList.length > 0) {
                        				wizard.steps[1].annexaFormly.fields.push({
                        					key: '',
                                            type: 'annexaLabel',
                                            className: 'col-sm-12',
                                            templateOptions: {
                                                label: '',
                                                value:'',
                                                labelClass: 'p-x-0 _700 text'
                                            },
                                            data: {
                                                informed: true,
                                                row: true,
                                                colClass: ' col-sm-12',
                                                labelClass: 'label-strong'
                                            },
                                            controller: function ($scope, $filter) {
                                                $scope.to.label = $filter("translate")("global.literals.newInteroperableSpecificValues");
                                            }
                        	        	});
                        				fieldPos = fieldPos + 1;
                        			}
                        			_.forEach(dataListNoGroup ,function(value) {
                        				wizard.modal.documentFactory.addFromlyFieldModality(value, wizard, fieldPos);
                            			fieldPos = fieldPos + 1;
                                    });
                        			
                        			var dataListGroup = $linq(data.voModalityDataList).where("x => x.chooseGroup != null").toArray();
                        			var groupName = $linq(dataListGroup).distinctBy("x => x.chooseGroup").select("x => x.chooseGroup").toArray();
                        			
                        			_.forEach(groupName ,function(group) {
                        				var dataListElements = $linq(dataListGroup).where("x => x.chooseGroup == '" + group + "'").toArray();
                        				var elementsName = $linq(dataListElements).distinctBy("x => x.chooseElement").select(function(x) {
                                            return { value: x.chooseElement }
                                        }).toArray();
                        				
                        				wizard.steps[1].annexaFormly.fields.push({
                        	        		key: group.replace(/\s+/g, ''),
                        	                type: 'annexaSelectRow',
                        	                className: 'col-sm-12',
                        	                data: {
                        	                    row:true,
                        	                    informed: true,
                        	                    clear: function($event,model,key, $select) {
                                                    $event.stopPropagation();
                                                    model[key] = undefined;
                                                    if($select) {
                                                        $select.selected = undefined;
                                                        $select.search = undefined;
                                                    }
                                                }
                        	                },
                        	                templateOptions: {
                        	                    label: group,
                        	                    required: true,
                        	                    focus: false,
                        	                    ngOptions: 'option[to.valueProp] as option in to.options',
                            					optionsAttr: 'bs-options',
                            					valueProp: 'value',
                            					labelProp: 'value',
                            					options: elementsName
                        	                }
                        	        	});
                        				fieldPos = fieldPos + 1;
                        				_.forEach(elementsName ,function(name) {
                            				var elements = $linq(dataListElements).where("x => x.chooseElement == '" + name.value + "'").toArray();
                            				_.forEach(elements ,function(value) {
                                				wizard.modal.documentFactory.addFromlyFieldModality(value, wizard, fieldPos);
                                				wizard.steps[1].annexaFormly.fields[fieldPos].hideExpression = function ($viewValue, $modelValue, scope) {
                                					if(!(value.chooseElement == scope.model[value.chooseGroup.replace(/\s+/g, '')])) {
                                						$viewValue = undefined;
                                						$modelValue = undefined;
                                						scope.model[value.packageUbication.replaceAll(".", "")] = undefined;
                                					}
                			                    	return !(value.chooseElement == scope.model[value.chooseGroup.replace(/\s+/g, '')]);
                			                    }
                                    			fieldPos = fieldPos + 1;
                                            });
                                        });
                                    });
                        			
                        		}).catch(function (error) {
                        			var DialogsFactory = angular.element(document.body).injector().get('DialogsFactory');
                        			var ErrorFactory = angular.element(document.body).injector().get('ErrorFactory');
                        			var $filter = angular.element(document.body).injector().get('$filter');
                        			DialogsFactory.error(ErrorFactory.getErrorMessage('documents', 'voDocument', error.data), $filter('translate')('DIALOGS_ERROR_MSG'));
                        			wizard.steps[1].annexaFormly.model.hasError = true;
                	        });
                        },
                        annexaFormly: {
                            fields: [
                            	{
                                    key: 'third',
                                    type: 'annexaRadioCheckboxRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    data: {
                                        informed: true,
                                        row: true
                                    },
                                    templateOptions: {
                                        type: 'radio',
                                        optionsAttr: 'bs-options',
                                        required: true,
                                        validate: true,
                                        options:[],
                                        ngOptions:'option[to.valueProp] as option in to.options',
                                        valueProp: 'id',
                                        labelProp: 'completeName',
                                        label: 'global.literals.receiver'
                                    },
                                    watcher: {
                                        type: '$watchCollection',
                                        expression: 'model.third',
                                        listener: function (field, _new, _old, scope) {
                                        	_.forEach(field.templateOptions.options ,function(option) {
                                        		var CommonService = angular.element(document.body).injector().get('CommonService');
                        	                	if(_new == option.id) {
                        	                		scope.model.selectedThird = option;
                        	                	}
                        	                	if(option.completeName == undefined) {
                        	                		option.completeName = CommonService.getThirdCompleteName(option);
                        	                	}
                        	                });
                                        }
                                    }
                            	},
                                {
                                    key: 'service',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12',
                                    data: {
                                        informed:true,
                                        row:true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            model.voGenericData = undefined;
                                            model.voModalityDataList = undefined;
                                            model.serviceLabel = undefined;
                                            model.idService = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        optionsAttr: 'bs-options',
                                        ngOptions:'option[to.valueProp] as option in to.options',
                                        label: 'global.literals.servicesWizard',
                                        valueProp: 'codeService',
                                        labelProp: 'name',
                                        placeholder: '',
                                        options: [],
                                        required: true,
                                        focus: false,
                                        onSelected: function($item) {
                                        	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                            $rootScope.$broadcast('modifyServiceEvent', {item: $item});
                                        }
                                    },
                                    controller: ['$scope', '$rootScope', 'DccumentsFactory', 'Language', 'GlobalDataFactory', function($scope, $rootScope, DccumentsFactory, Language, GlobalDataFactory) {

                                        $scope.$on('modifyServiceEvent', function(event, args) {
                                            if(args && args.item && args.item.voModalityList) {
                                            	$scope.model.modality = undefined;
                                            	$scope.model.finalitats = undefined;
                                            	$scope.fields[2].templateOptions.options = args.item.voModalityList;
                                            	$scope.model.serviceLabel = args.item.name;
                                            	$scope.model.idService = args.item.id;
                                            }
                                        });
                                    }]
                                },
                                {
                                    key: 'modality',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12',
                                    data: {
                                        informed:true,
                                        row:true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            model.modalityLabel = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        optionsAttr: 'bs-options',
                                        ngOptions:'option[to.valueProp] as option in to.options',
                                        label: 'global.literals.modalitiesWizard',
                                        valueProp: 'codeModality',
                                        labelProp: 'name',
                                        placeholder: '',
                                        options: [],
                                        required: true,
                                        focus: false,
                                        onSelected: function($item) {
                                        	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                            $rootScope.$broadcast('modifyModalityEvent', {item: $item});
                                        }
                                    },
                                    controller: ['$scope', '$rootScope', 'DccumentsFactory', 'Language', 'GlobalDataFactory', function($scope, $rootScope, DccumentsFactory, Language, GlobalDataFactory) {

                                        $scope.$on('modifyModalityEvent', function(event, args) {
                                            if(args && args.item) {
                                            	$scope.model.finalitats = undefined;
                                            	$scope.model.voGenericData = args.item.voGenericData;
                                            	$scope.model.voModalityDataList = args.item.voModalityDataList;
                                            	$scope.model.modalityLabel = args.item.name;
                                            	$scope.fields[3].templateOptions.options = args.item.finalitats;
                                            }
                                        });
                                    }]
                                },
                                {
                                    key: 'finalitats',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12',
                                    data: {
                                        informed:true,
                                        row:true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        optionsAttr: 'bs-options',
                                        ngOptions:'option[to.valueProp] as option in to.options',
                                        label: 'global.literals.finalitatsWizard',
                                        valueProp: 'code',
                                        labelProp: 'name',
                                        placeholder: '',
                                        options: [],
                                        required: true,
                                        focus: false
                                    }
                                }
                            ]
                        }
                    },
                    {
                        title: '',
                        key: 'citizenValues',
                        disabled: false,
                        disableNext: function(model, wizard) {
                            var selected = false;
                            if(wizard.steps[1].annexaFormly.form.$valid && !model.hasError){
                                selected = true;
                            }
                            return !selected;
                        },
                        beforeNext: function (step, wizard) {
                        	wizard.steps[1].annexaFormly.model.voGenericData = {};
                			wizard.steps[1].annexaFormly.model.voGenericData.citizenFullName = wizard.steps[1].annexaFormly.model.citizenFullName;
                			wizard.steps[1].annexaFormly.model.voGenericData.citizenNIF = wizard.steps[1].annexaFormly.model.citizenNIF;
                			wizard.steps[1].annexaFormly.model.voGenericData.citizenNIFType = wizard.steps[1].annexaFormly.model.citizenNIFType;
                			wizard.steps[1].annexaFormly.model.voGenericData.citizenName = wizard.steps[1].annexaFormly.model.citizenName;
                			wizard.steps[1].annexaFormly.model.voGenericData.citizenSecondSurname = wizard.steps[1].annexaFormly.model.citizenSecondSurname;
                			wizard.steps[1].annexaFormly.model.voGenericData.citizenSurname = wizard.steps[1].annexaFormly.model.citizenSurname;
                        },
                        annexaFormly: {
                            fields: [
                            	{
                                    key: '',
                                    type: 'annexaLabel',
                                    className: 'col-sm-12',
                                    templateOptions: {
                                        label: '',
                                        value:'',
                                        labelClass: 'p-x-0 _700 text'
                                    },
                                    data: {
                                        informed: true,
                                        row: true,
                                        colClass: ' col-sm-12',
                                        labelClass: 'label-strong'
                                    },
                                    controller: function ($scope, $filter) {
                                        $scope.to.label = $filter("translate")("global.literals.newInteroperableGenericValues");
                                    }
                                },
                            	{
                                    key: 'citizenFullName',
                                    type: 'annexaInputRow',
                                    className: 'col-sm-12',
                                    data: {
                                        row: true,
                                        colClass: '',
                                        labelClass: 'label-strong',
                                        informed: true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        type: 'text',
                                        label: 'global.literals.citizenFullName',
                                        required: false,
                                        focus: false,
                                        updateOnBlur:true
                                    }
                                },{
                                    key: 'citizenNIF',
                                    type: 'annexaInputRow',
                                    className: 'col-sm-12',
                                    data: {
                                        row: true,
                                        colClass: '',
                                        labelClass: 'label-strong',
                                        informed: true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        type: 'text',
                                        label: 'global.literals.citizenNIF',
                                        required: true,
                                        focus: false,
                                        updateOnBlur:true
                                    }
                                },{
                                    key: 'citizenNIFType',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12',
                                    data: {
                                        informed:true,
                                        row:true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        optionsAttr: 'bs-options',
                                        ngOptions:'option[to.valueProp] as option in to.options',
                                        label: 'global.thirds.literals.identificationdocumenttype',
                                        valueProp: 'id',
                                        labelProp: 'name',
                                        placeholder: '',
                                        options: [],
                                        required: true,
                                        focus: false
                                    },
                                    controller: ['$scope', '$rootScope', 'DccumentsFactory', 'Language', 'GlobalDataFactory', function($scope, $rootScope, DccumentsFactory, Language, GlobalDataFactory) {
                                    	$scope.to.options = [
                                            { id: "DNI", name: "DNI/NIF"},
                                            { id: "NIE", name: "NIE/NIF"},
                                            { id: "NIF", name: "NIF"}
                                        ];
                                    }]
                                },{
                                    key: 'citizenName',
                                    type: 'annexaInputRow',
                                    className: 'col-sm-12',
                                    data: {
                                        row: true,
                                        colClass: '',
                                        labelClass: 'label-strong',
                                        informed: true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        type: 'text',
                                        label: 'global.literals.citizenName',
                                        required: false,
                                        focus: false,
                                        updateOnBlur:true
                                    }
                                },{
                                    key: 'citizenSurname',
                                    type: 'annexaInputRow',
                                    className: 'col-sm-12',
                                    data: {
                                        row: true,
                                        colClass: '',
                                        labelClass: 'label-strong',
                                        informed: true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        type: 'text',
                                        label: 'global.literals.citizenSurname',
                                        required: false,
                                        focus: false,
                                        updateOnBlur:true
                                    }
                                },{
                                    key: 'citizenSecondSurname',
                                    type: 'annexaInputRow',
                                    className: 'col-sm-12',
                                    data: {
                                        row: true,
                                        colClass: '',
                                        labelClass: 'label-strong',
                                        informed: true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        type: 'text',
                                        label: 'global.literals.citizenSecondSurname',
                                        required: false,
                                        focus: false,
                                        updateOnBlur:true
                                    }
                                }
                            ]
                        }
                    },
                    {
                        title: '',
                        key: 'documentValues',
                        disabled: false,
                        disableNext: function(model, wizard) {
                            var selected = false;
                            if(wizard.steps[2].annexaFormly.form.$valid){
                                selected = true;
                            }
                            return !selected;
                        },
                        annexaFormly: {
                            fields: [
                                {
                                    key: 'modal_body',
                                    className: 'modal-body ',
                                    fieldGroup: [
                                        {
                                            key: 'typeSelect',
                                            type: 'annexaSelectRow',
                                            className: 'col-sm-12',
                                            templateOptions: {
                                                optionsAttr: 'bs-options',
                                                ngOptions: 'option[to.valueProp] as option in to.options | languagefilter: $select.search',
                                                label: 'global.literals.type',
                                                valueProp: 'id',
                                                labelProp: 'language1',
                                                placeholder: '',
                                                options: [],
                                                required: true,
                                                clearHide: true
                                            },
                                            controller: ['$scope', '$rootScope', 'DccumentsFactory', 'Language', 'GlobalDataFactory', function($scope, $rootScope, DccumentsFactory, Language, GlobalDataFactory) {
                                                $scope.to.labelProp = Language.getActiveColumn();
                                                $scope.to.options = [];
                                                var types = GlobalDataFactory.documentTypes;
                                                if($scope.to.optionsAux && $scope.to.optionsAux.length > 0){
                                                	types = $scope.to.optionsAux;
                                                }
                                                _.forEach (types, function(docType){
                                                	var canUseType = true;
                                                	if($scope.originalModel && $scope.originalModel.module && $scope.originalModel.module == 'REGSALIDA'
                                                		&& !docType.includeRegisterEntry){
                                                		canUseType = false;
                                                	}
                                                    if(docType.profiles && docType.profiles.length > 0){
                                                        // intersection de perfil de usuario y los perfiles de documentTypeProfile
                                                        var profiles = $linq(docType.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
                                                            if(y.profile){
                                                                return (x.profile.id == y.profile.id);
                                                            }
                                                            // en este caso es por si hubiera un null, ya que admitimos todos los perfiles.
                                                            else{
                                                                return true;
                                                            }
                                                        }).toArray();
                                                        if(profiles && profiles.length > 0 && canUseType){
                                                            $scope.to.options.push(angular.copy(docType));
                                                        }
                                                        else{
                                                        }
                                                    }
                                                    else if(canUseType){
                                                        $scope.to.options.push(angular.copy(docType));
                                                    }
                                                });
                                            }],
                                            data: {
                                                row: true,
                                                informed: true,
                                                colClass: ' col-sm-12',
                                                labelClass: 'label-strong'
                                            }
                                        },
                                        {
                                            key: 'title',
                                            type: 'annexaInputRow',
                                            className: 'col-sm-12',
                                            data: {
                                                row: true,
                                                colClass: ' col-sm-12',
                                                labelClass: 'label-strong',
                                                informed: true,
                                                clear: function ($event, model, key, $select) {
                                                    $event.stopPropagation();
                                                    model[key] = undefined;
                                                    if ($select) {
                                                        $select.selected = undefined;
                                                        $select.search = undefined;
                                                    }
                                                }
                                            },
                                            templateOptions: {
                                                type: 'text',
                                                label: 'global.literals.title',
                                                required: true,
                                                focus: false,
                                                placeholder:'',
                                                updateOnBlur: true
                                            },
                                            controller: ['$scope', 'DccumentsFactory', 'Language', '$rootScope', 'GlobalDataFactory', function($scope, DccumentsFactory, Language, $rootScope, GlobalDataFactory) {
                                                var titleAux = '';
                                                if($scope.model.typeSelect) {
                                                    var selectedType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $scope.model.typeSelect);

                                                    if(selectedType) {
                                                        $scope.to.placeholder = selectedType[Language.getActiveColumn()];
                                                    } else {
                                                        $scope.to.placeholder = '';
                                                    }
                                                } else {
                                                    $scope.to.placeholder = '';
                                                }

                                                $scope.to.onFocus = function(viewValue){
                                                    if($rootScope && ($rootScope.esetMode || ($rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.validate_title_document && $rootScope.app.configuration.validate_title_document.value))){
                                                        if($scope.model.typeSelect) {
                                                            var selectedType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $scope.model.typeSelect);
                                                            if(selectedType) {                                                	
                                                            	if ($scope.lastSelectedType) {
                                                            		//Ya hay un tipo de documento seleccionado
                                                            		var pos = $scope.model.title.indexOf($scope.lastSelectedType);
                                                            		if (pos >= 0) {
                                                            			//Encuentra el acronimo del tipo de documento anterior, lo reemplaza por el nuevo acronimo
                                                            			$scope.model.title = $scope.model.title.replace($scope.lastSelectedType, selectedType.acronym);
                                                            		} else {
                                                            			//No encuentra el acronimo del tipo de documento anterior, pone el nuevo acronimo al principio
                                                            			$scope.model.title = selectedType.acronym + ' ' + $scope.model.title;
                                                            		}                                                		                                                		
                                                            	} else {
                                                            		//No hay un tipo de documento seleccionado
                                                            		if (viewValue) {
                                                            			if (viewValue.startsWith(selectedType.acronym + ' ')) {
                                                            				$scope.model.title = viewValue;
                                                            			} else {
                                                            				$scope.model.title = selectedType.acronym + ' ' + viewValue;
                                                            			}
                                                            		} else {
                                                            			$scope.model.title = selectedType.acronym + ' ';
            	                                                    }
                                                            	}                                                	
                                                            	titleAux = angular.copy($scope.model.title);
                                                            	$scope.lastSelectedType = selectedType.acronym;
                                                            }                                                
                                                        }
                                                    }
                                                };

                                            }]
                                        },
                                        {
                                            key: 'archiveClassification',
                                            type: 'annexaSelectTreeRow',
                                            className: 'col-sm-12',
                                            templateOptions: {
                                                label: 'global.literals.classificationBox',
                                                options: [],
                                                required: true,
                                                disabled: true,
                                                focus: false
                                            },
                                            controller:['$scope', 'CommonService', 'DccumentsFactory', 'Language', 'GlobalDataFactory', function($scope, CommonService, DccumentsFactory, Language, GlobalDataFactory) {
                                                $scope.to.options = CommonService.getTreeData(GlobalDataFactory.archiveClassifications, Language.getActiveColumn());
                                                $scope.to.labelProp = Language.getActiveColumn();
                                                $scope.to.onSelected = function(index) {
                                                    $scope.model.modifyArchiveClassification = true;
                                                    $scope.model.archiveClassification = index.id;
                                                };
                                                if(!$scope.model.archiveClassification) {
                                                	$scope.to.disabled = false;
                                                }
                                            }],
                                            data: {
                                                row: true,
                                                informed: true,
                                                colClass: ' col-sm-12',
                                                labelClass: 'label-strong'
                                            },
                                            watcher:{
                                                type: '$watchCollection',
                                                expression: 'model.typeSelect',
                                                listener: function(field, _new, aux,scope) {
                                                    var DccumentsFactory = angular.element(document.body).injector().get('DccumentsFactory');
                                                    DccumentsFactory.setFileArchiveClassification(scope.model);
                                                }
                                            }
                                        },
                                        {
                                            key: 'section',
                                            type: 'annexaSelectRow',
                                            className: 'col-sm-12',
                                            templateOptions: {
                                                optionsAttr: 'bs-options',
                                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                                label: 'global.literals.section',
                                                valueProp: 'id',
                                                labelProp: 'language1',
                                                placeholder: '',
                                                options: [],
                                                required: true,
                                                clearHide: true
                                            },
                                            controller: ['$scope', 'Language', 'GlobalDataFactory', 'DccumentsFactory', function($scope, Language, GlobalDataFactory, DccumentsFactory) {
                                                $scope.to.options = DccumentsFactory.sectionsUser;
                                                $scope.to.labelProp = Language.getActiveColumn();
                                            }],
                                            data: {
                                                row: true,
                                                informed: true,
                                                colClass: ' col-sm-12',
                                                labelClass: 'label-strong'
                                            },
                                            hideExpression: function ($viewValue, $modelValue, scope) {
                                                return scope.model.oneSection ? true : false;
                                            }
                                        },
                                        {
                                            key: 'profiles',
                                            type: 'annexaMultipleSelectRow',
                                            className: 'col-sm-12',
                                            templateOptions: {
                                                optionsAttr: 'bs-options',
                                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                                label: 'global.literals.profiles',
                                                valueProp: 'id',
                                                labelProp: 'completeName',
                                                placeholder: '',
                                                options: [],
                                                required: true
                                            },
                                            data: {
                                                row: true,
                                                informed: true,
                                                colClass: ' col-sm-12',
                                                labelClass: 'label-strong'
                                            },
                                            controller:['$scope', 'Language', 'GlobalDataFactory', 'DccumentsFactory', 'HelperService', function($scope, Language, GlobalDataFactory, DccumentsFactory, HelperService) {
                                                $scope.to.labelProp = Language.getActiveColumn();
                                                $scope.to.options = $scope.model.module != 'EXPED' ? DccumentsFactory.profilesUser :
                                                    $linq(DccumentsFactory.profilesUser).intersect($linq(JSOG.decode($scope.model.extra).processProfiles).select("x => x.profile").toArray(), "(x, y) => x.id == y.id").toArray();
                                                
                                                $scope.to.options = $linq($scope.to.options).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                                                
                                                if($scope.to.options && $scope.to.options.length == 1){
                                                    if($scope.model.profiles){
                                                        $scope.model.profiles.push($scope.to.options[0].id);
                                                    }
                                                    else{
                                                        $scope.model.profiles = [];
                                                        $scope.model.profiles.push($scope.to.options[0].id);
                                                    }
                                                }else if($scope.model.module == 'EXPED'){
                                                    if(!$scope.model.profiles){
                                                        $scope.model.profiles = [];
                                                    }else{
                                                        $scope.model.profiles.length = 0;
                                                    }
                                                }
                                            }],
                                            hideExpression: function ($viewValue, $modelValue, scope) {
                                                return scope.model.oneProfile ? true : false;
                                            }

                                        }
                                    ]
                                }
                            ]
                        }
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        fromScannerWizard: {
            title: 'global.literals.fromScannerWizard',
            size: '',
            icon: '&#xe329',
            wizard: {
                modal: undefined,
                finishWizard: function () {
                    if(this.modal) {
                        this.modal.submitModal();
                    }
                },
                exitValidation: function(form) {
                    return form && !form.$invalid;
                },
                steps: [
                    {
                        title: 'global.literals.fromScannerWizardStep1',
                        key: 'selectDocuments',
                        disabled: false,
                        disableNext: function(model, wizard) {
                            var selected = false;
                            var $rootScope = angular.element(document.body).injector().get('$rootScope');
                            if($rootScope && ($rootScope.esetMode  || ($rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.validate_title_document && $rootScope.app.configuration.validate_title_document.value))){
                                wizard.steps[1].disabled = true;
                                wizard.steps[2].disabled = true;
                                _.forEach(Object.keys(model.documents), function (value) {
                                    selected = selected || model.documents[value];
                                    var indexStep = $linq(wizard.steps).indexOf(function (x) { return (x.key == value); });

                                    if (indexStep != -1) {
                                        wizard.steps[indexStep].disabled = !model.documents[value];
                                    }
                                });
                            }else {
                                wizard.steps[1].disabled = false;
                                wizard.steps[2].disabled = false;
                                _.forEach(Object.keys(model.documents), function (value) {
                                    selected = selected || model.documents[value];
                                    var indexStep = $linq(wizard.steps).indexOf(function (x) { return (x.key == value); });
                                    if (indexStep != -1) {
                                        wizard.steps[indexStep].disabled = true;
                                    }
                                });
                            }
                            return !selected;
                        },
                        exitValidation: function(model) {
                            var selected = false;

                            _.forEach(Object.keys(model.documents),function(value) {
                                selected = selected || model.documents[value];
                            });

                            return selected;
                        },
                        annexaFormly: {
                            fields: [
                                {
                                    key: 'selectAll',
                                    type: 'annexaSelectAllRow',
                                    className: '',
                                    templateOptions: {
                                        type: 'checkbox',
                                        label: 'global.bpm.selectAll',
                                        validate: false,
                                        required: false,
                                        changeValue: function(){}
                                    },
                                    controller:['$scope', function($scope) {
                                        $scope.to.changeValue = function(){
                                            if($scope.model.documents){
                                                var keys = Object.keys($scope.model.documents);
                                                _.forEach(keys, function(key){
                                                    if($scope.model.selectAll){
                                                        $scope.model.documents[key] = true;
                                                    }else{
                                                        $scope.model.documents[key] = false;
                                                    }
                                                });
                                            }
                                        };
                                    }],
                                    hideExpression: function($viewValue, $modelValue, scope) {
                                        if(scope.model.isUploadDocuments){
                                            return true;
                                        }
                                        var keys = Object.keys(scope.model.documents);

                                        if(keys && keys.length < 2){
                                            return true;
                                        }
                                        return false;
                                    }
                                },
                                {
                                    key: 'documents',
                                    type: 'annexaRadioCheckboxRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    data: {
                                        informed: true,
                                        row: true
                                    },
                                    templateOptions: {
                                        type: 'checkHTMLOneModel',
                                        optionsAttr: 'bs-options',
                                        required: true,
                                        validate: true,
                                        options:[],
                                        ngOptions:'option[to.valueProp] as option in to.options',
                                        valueProp: 'id',
                                        labelProp: 'value'
                                    },
                                    watcher:{
                                        type: '$watchCollection',
                                        expression: 'model.documents',
                                        listener: function(field, _new, _old, scope) {
                                            var keys = Object.keys(_new);
                                            var isAllTrue = true;
                                            if(keys) {
                                                _.forEach(keys, function(key){
                                                    isAllTrue = isAllTrue && (_new[key])?true : false;
                                                });
                                            }else{
                                                isAllTrue = false;
                                            }
                                            scope.model.selectAll = isAllTrue;
                                        }
                                    },
                                    hideExpression: function($viewValue, $modelValue, scope) {
                                        if(scope.model.isUploadDocuments){
                                            return true;
                                        }
                                        return false;
                                    },
                                    controller: ['$scope', '$rootScope', 'DccumentsFactory', function($scope, $rootScope, DccumentsFactory){
                                        $scope.to.showObject = function(option){
                                            if(option && option.id){
                                                $rootScope.loading(true);
                                                var windowReference = window.open();
                                                DccumentsFactory.getScanDocumentContent(option.id).then(function(data) {
                                                	windowReference.location = data;
                                                    $rootScope.loading(false);
                                                }).catch(function (error) {
                                                	windowReference.close();
                                                    $rootScope.loading(false);
                                                });
                                            }
                                        }
                                    }]
                                },
                                {
                                    key: 'documentsLabels',
                                    type: 'annexaLabelsRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    templateOptions: {
                                        labels: []
                                    },
                                    controller: ['$scope', function($scope) {
                                        if($scope.model.documents){
                                            $scope.to.labels = Object.keys($scope.model.documents);
                                        }else{
                                            $scope.to.labels = [];
                                        }
                                    }],
                                    data: {
                                        informed:true,
                                        row:true,
                                        colClass: ' col-sm-12',
                                        labelClass: 'label-strong'
                                    },
                                    hideExpression: function($viewValue, $modelValue, scope) {
                                        if(scope.model.isUploadDocuments){
                                            return false;
                                        }
                                        return true;
                                    }
                                }
                            ]
                        }
                    },
                    {
                        title: 'global.literals.fromScannerWizardStep2',
                        key: 'selectDocumentsUpload',
                        disabled: true,
                        disableNext: function(model, wizard) {
                            var selected = false;
                            if(model.uploadType){
                                _.forEach(Object.keys(wizard.steps[0].annexaFormly.model.documents),function(value) {
                                    var indexStep = $linq(wizard.steps).indexOf(function (x) { return (x.key == value); });

                                    if (indexStep != -1) {
                                        if(model.uploadType == 1){
                                            wizard.steps[indexStep].disabled = !wizard.steps[0].annexaFormly.model.documents[value];
                                        }else{
                                            wizard.steps[indexStep].disabled = true;
                                        }
                                    }

                                });
                                if(wizard.steps.length > 3 && wizard.steps[2].key == "documentAllInOne") {
                                    if(model.uploadType == 1){
                                        wizard.steps[2].disabled = true;
                                    }else{
                                        wizard.steps[2].disabled = false;
                                    }
                                }

                                selected = true;
                            }
                            return !selected;
                        },
                        exitValidation: function(model) {
                            var selected = false;
                            if(model.uploadType){
                                selected = true;
                            }
                            return selected;
                        },
                        annexaFormly: {
                            fields: [
                                {
                                    key: 'uploadType',
                                    type: 'annexaRadioCheckboxRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    data: {
                                        informed: true,
                                        row: true
                                    },
                                    templateOptions: {
                                        type: 'radio',
                                        optionsAttr: 'bs-options',
                                        required: true,
                                        validate: true,
                                        options:[],
                                        ngOptions:'option[to.valueProp] as option in to.options',
                                        valueProp: 'id',
                                        labelProp: 'value'
                                    },
                                    controller: function ($scope, $filter) {
                                        $scope.to.options = [
                                            { id:1, value: $filter("translate")("global.documents.uploadSimpleDocuments") },
                                            { id:2, value: $filter("translate")("global.documents.uploadDocuments") }
                                        ];
                                    }
                                }
                            ]
                        }
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        pluginCreate: {
            title: 'global.literals.pluginCreate',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <annexa-plugins-create data="modal.data" submit-function="modal.submitFunction"></annexa-plugins-create>',
                '</div>'
            ],
            alerts: []
        },
        selectDossierTransactionToAddDocument: {
            title: 'global.literals.modalNewDocumentTitle',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
								 key: 'dossierTransactionAdded',
								 type: 'annexaRadioCheckboxRow',
								 className: 'col-sm-12 daughter-label-strong',
								 data: {
								     informed: true,
								     row: true
								 },
								 templateOptions: {
								     type: 'radio',
								     optionsAttr: 'bs-options',
								     required: true,
								     validate: true,
								     options:[],
								     ngOptions:'option[to.valueProp] as option in to.options',
								     valueProp: 'id',
								     labelProp: 'value',
								     label: 'global.tram.literals.select_open_dossier_transaction'
								 },
								 controller: ['$scope', '$filter', 'Language', function ($scope, $filter, Language) {
									 $scope.to.options = [];
							         if($scope.model.dossierTransactions && $scope.model.dossierTransactions.length > 0){
							        	 _.forEach($scope.model.dossierTransactions, function(dossierTransaction){
							        		 $scope.to.options.push({ id:dossierTransaction.id, value: dossierTransaction[Language.getActiveColumn()] });
							        	 });
							         }
								 }]
                            },
                            {
 								 key: 'createdDocumentType',
 								 type: 'annexaRadioCheckboxRow',
 								 className: 'col-sm-12 daughter-label-strong',
 								 data: {
 								     informed: true,
 								     row: true
 								 },
 								 templateOptions: {
 								     type: 'radio',
 								     optionsAttr: 'bs-options',
 								     required: true,
 								     validate: true,
 								     options:[],
 								     ngOptions:'option[to.valueProp] as option in to.options',
 								     valueProp: 'id',
 								     labelProp: 'value',
 								     label: 'global.tram.literals.created_document_type'
 								 },
 								 controller: ['$scope', '$filter', 'Language', function ($scope, $filter, Language) {
 									 $scope.to.options = [];
 							         $scope.to.options.push({ id:'tram', value: $filter('translate')('global.tram.literals.from_tram_template') });
 							         $scope.to.options.push({ id:'doc', value: $filter('translate')('global.tram.literals.from_document') });
 								 }]
                           }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        downloadDocumentsModal: {
            title: 'global.literals.downloadDocuments',
            size: '',
            icon: 'fa fa-download',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '	<div class="col-xs-12">',
	            '   	<div class="input-group box-tool-group">',
	            '       	<span class="input-group-btn">',
	            '				<span class="btn-sm p-l-xs p-r-0 text-grey" ><i class="fa fa-search" aria-hidden="true"></i></span>',
	            '			</span>',
	            '			<div class="ico-floating-wrapper">',
	            '				<input type="text" class="form-control-sm b-a-0" ng-model="modal.searchedValue" aria-label="{{\'global.literals.search\' | translate}}" placeholder="{{modal.placeholder | translate}}" uib-typeahead="result as result.value for result in modal.searchFunc({val: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-min-length="3" typeahead-wait-ms="300" typeahead-editable="false" typeahead-focus-first="true" typeahead-on-select="modal.typeaheadSelect($item)" />',
	            '				<span class="ico-floating" aria-hidden="true">',
	            '					<i ng-show="loadingBox" class="fa fa-spinner fa-pulse "></i>',
	            '					<i ng-show="noResultBox" class="fa fa-ban "></i>',
	            '				</span>',
	            '			</div>',
	            '			<span class="input-group-btn">',
	            '				<button type="button" class="btn-sm btn-accept btn" ng-click="modal.searchAdd()">{{modal.addText | translate}}</button>',
	            '			</span>',
	            '		</div>',
	            '		<button class="text-primary cursiva-grey no-bg no-border pull-left" ng-click="modal.advancedSearch()"><span translate="global.literals.advanced_search">Busqueda avanzada</span></button> ',
                '	</div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-xs-12">',
                '       <div class=" container-fluid m-b-lg grey-100 p-y-sm b-all ">',
                '           <h6 class="p-x-0 form-control-label"><span translate="global.literals.documentsToDownload"></span></h6>',
                '           <table class="table table-hover m-a-0 ">',
                '			  <thead>',
                '				<tr>',
                '					<th style="width: 20%;"> <span translate="global.literals.code"> </th>',
                '					<th style="width: 20%;"> <span translate="global.literals.title"> </th>',
                '					<th style="width: 20%;"></th>',
                '				</tr>',
                '			  </thead>',
                '             <tbody>',
                '               <tr ng-repeat="document in modal.documentsToDownload">',
                '                   <td ng-style="column.style" class="ng-scope" style="width: 20%;">',
                '                       <span>{{document.code}}</span>',
                '                   </td>',
                '                   <td ng-style="column.style" class="ng-scope" style="width: 70%;">',
                '                       <span>{{document.title}}</span>',
                '                   </td>',
                '                   <td ng-style="column.style" class="ng-scope" style="width: 10%;">',
                '                       <button type="button" class="btn btn-sm btn-danger" ng-click="modal.removeDocument($index)"><span class="fa fa-trash"></span></button>',
                '                   </td>',
                '               </tr>',
                '             </tbody>',
                '           </table>',
                '       </div>',
                '   </div>',
                '</div>',
            ],
            alerts: []
        },
        exportDossierModal: {
            title: 'global.literals.exportDossier',
            size: '',
            icon: 'fa fa-download',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '	<div class="col-xs-12">',
	            '   	<div class="input-group box-tool-group">',
	            '       	<span class="input-group-btn">',
	            '				<span class="btn-sm p-l-xs p-r-0 text-grey" ><i class="fa fa-search" aria-hidden="true"></i></span>',
	            '			</span>',
	            '			<div class="ico-floating-wrapper">',
	            '				<input type="text" class="form-control-sm b-a-0" ng-model="modal.searchedValue" aria-label="{{\'global.literals.search\' | translate}}" placeholder="{{modal.placeholder | translate}}" uib-typeahead="result as result.value for result in modal.searchFunc({val: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-min-length="3" typeahead-wait-ms="300" typeahead-editable="false" typeahead-focus-first="true" typeahead-on-select="modal.typeaheadSelect($item)" />',
	            '				<span class="ico-floating" aria-hidden="true">',
	            '					<i ng-show="loadingBox" class="fa fa-spinner fa-pulse "></i>',
	            '					<i ng-show="noResultBox" class="fa fa-ban "></i>',
	            '				</span>',
	            '			</div>',
	            '			<span class="input-group-btn">',
	            '				<button type="button" class="btn-sm btn-accept btn" ng-click="modal.searchAdd()">{{modal.addText | translate}}</button>',
	            '			</span>',
	            '		</div>',
	            '		<button class="text-primary cursiva-grey no-bg no-border pull-left" ng-click="modal.advancedSearch()"><span translate="global.literals.advanced_search">Busqueda avanzada</span></button> ',
                '	</div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-xs-12">',
                '       <div class=" container-fluid m-b-lg grey-100 p-y-sm b-all ">',
                '           <h6 class="p-x-0 form-control-label"><span translate="global.literals.dossierToExport"></span></h6>',
                '           <table class="table table-hover m-a-0 ">',
                '			  <thead>',
                '				<tr>',
                '					<th style="width: 20%;"> <span translate="global.literals.code"> </th>',
                '					<th style="width: 20%;"> <span translate="global.literals.title"> </th>',
                '					<th style="width: 20%;"></th>',
                '				</tr>',
                '			  </thead>',
                '             <tbody>',
                '               <tr ng-repeat="dossier in modal.dossierToExport">',
                '                   <td ng-style="column.style" class="ng-scope" style="width: 20%;">',
                '                       <span>{{dossier.code}}</span>',
                '                   </td>',
                '                   <td ng-style="column.style" class="ng-scope" style="width: 70%;">',
                '                       <span>{{dossier.title}}</span>',
                '                   </td>',
                '                   <td ng-style="column.style" class="ng-scope" style="width: 10%;">',
                '                       <button type="button" class="btn btn-sm btn-danger" ng-click="modal.removeDossier($index)"><span class="fa fa-trash"></span></button>',
                '                   </td>',
                '               </tr>',
                '             </tbody>',
                '           </table>',
                '       </div>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-xs-12">',
                '		<input type="checkbox" ng-model="modal.eniExp" class="m-r-0">',
                '			<span translate="global.literals.saveEniExp">',
                '		</input>',
                '	</div>',
                '</div>'
                
            ],
            alerts: []
        },
        showStatesPublish: {
            title: 'global.literals.show_status_publish',
            size: 'modal-lg',
            icon: 'fa fa-check-square-o',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12" ng-if="modal.data">',
                '       <annexa-publish-statuses-evidences statuses="modal.data.statuses"></annexa-publish-statuses-evidences>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        createOperationInvoices: {
            title: 'global.operationsAndInvoices.new',
            size: 'modal-lg',
            icon: 'fa fa-plus',
            wizard: {
                modal: undefined,
                finishWizard: function () {
                    if(this.modal) {
                        this.modal.submitModal();
                    }
                },
                exitValidation: function(form) {
                    return form && !form.$invalid;
                },
                steps: [
                    {
                        title: 'global.operationType.operationType',
                        key: 'selectOperationType',
                        disabled: false,
                        isFinal: false,
                        disableNext: function(model, wizard) {
                        	var selected = false;
                        	if(model && model.operationType && model.operationType == 'OPERATION'){
                        		wizard.steps[1].disabled = false;
                        		wizard.steps[2].disabled = true;
                        		selected = true;
                        	}else if(model && model.operationType && model.operationType == 'INVOICE'){
                        		wizard.steps[1].disabled = true;
                        		wizard.steps[2].disabled = false;
                        		selected = true;
                        	}else{
                        		wizard.steps[1].disabled = true;
                        		wizard.steps[2].disabled = true;
                        	}
                            return !selected;
                        },
                        exitValidation: function(model) {
                            var selected = false;
                            if(model && model.operationType){
                                selected = true;
                            }
                            return selected;
                        },
                        annexaFormly: {
                            fields: [
                                {
                                    key: 'operationType',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    data: {
                                        informed: true,
                                        row: true,
                                        clear: function ($event, model, key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if ($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        optionsAttr: 'bs-options',
                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                        label: '',
                                        valueProp: 'id',
                                        labelProp: 'name',
                                        placeholder: '',
                                        options: [],
                                        required: true
                                    },
                                    watcher: {
                                        type: '$watchCollection',
                                        expression: 'model',
                                        listener: function (field, _new) {
                                            if (field.formControl) {
                                                field.formControl.$validate();
                                            }
                                        }
                                    },
                                    controller: ['$scope','$filter','GlobalDataFactory', function ($scope, $filter, GlobalDataFactory) {
                                        if(GlobalDataFactory.operationTypesCode && GlobalDataFactory.operationTypesCode.length > 0){
                                        	$scope.to.options = [];
                                            _.forEach(GlobalDataFactory.operationTypesCode, function(item){
                                            	$scope.to.options.push({id:item.id, name:$filter('translate')('global.operationsAndInvoices.'+item.name)});
                                            });
                                            if($scope.to.options.length == 1){
                                            	$scope.model.operationType = $scope.to.options[0].id;
                                            }
                                        }
                                    }]
                                }
                            ]
                        }
                    },
                    {
                        title: 'global.operationsAndInvoices.operation_handler_finally',
                        key: 'deleteTransactionNewTram',
                        disabled: true,
                        isFinal: true,
                        disableNext: function(model, wizard) {
                            return true;
                        },
                        exitValidation: function(model) {
                            var selected = true;
                            return selected;
                        },
                        afterNext: function (step, wizard) {
                            var a = 0;
                        },
                        annexaFormly: {
                            fields: [
                            	{
                                    key: 'instanceCode',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12',
                                    data: {
                                        informed:true,
                                        row:true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        optionsAttr: 'bs-options',
                                        ngOptions: 'option in to.options | filter: $select.search',
                                        label: 'global.operationsAndInvoices.instance',
                                        valueProp: 'code',
                                        labelProp: 'code',
                                        placeholder: '',
                                        options: [],
                                        required: true,
                                        focus: true,
                                        onSelected: function(item, options) {
                                            var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                            $rootScope.$broadcast('newAccountingInstanceSelected', { item: item });
                                        }
                                    },
                                    hideExpression: function($viewValue, $modelValue, scope) {
                                    	var TramFactory = angular.element(document.body).injector().get('TramFactory');
                                        return ((TramFactory && TramFactory.userAccountInstances && TramFactory.userAccountInstances.length > 1)?false:true);
                                    },
                                    controller: ['$scope', 'TramFactory', function($scope, TramFactory) {
                                        $scope.to.options = ((TramFactory.userAccountInstances)?TramFactory.userAccountInstances:[]);
                                    }]
                                },
                                {
                                    key: 'instanceName',
                                    type: 'annexaLabelRow',
                                    className: 'col-sm-12',
                                    templateOptions: {
                                        label: 'global.operationsAndInvoices.instance',
                                        value:''
                                    },
                                    data: {
                                        informed: true,
                                        row: true
                                    },
                                    hideExpression: function($viewValue, $modelValue, scope) {
                                    	var TramFactory = angular.element(document.body).injector().get('TramFactory');
                                        return ((TramFactory && TramFactory.userAccountInstances && TramFactory.userAccountInstances.length > 1)?true:false);
                                    },
                                    controller: ['$scope','TramFactory', '$filter', function($scope, TramFactory, $filter) {
                                    	$scope.to.label = $filter('translate')('global.operationsAndInvoices.instance');
                                    	$scope.to.value = ((TramFactory && TramFactory.userAccountInstances && TramFactory.userAccountInstances.length == 1)?TramFactory.userAccountInstances[0].code:"");
                                    }]
                                },
                                {
                                    key: 'areGes',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12',
                                    data: {
                                        informed:true,
                                        row:true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        optionsAttr: 'bs-options',
                                        ngOptions: 'option in to.options | filter: $select.search',
                                        label: 'global.operationsAndInvoices.areGes',
                                        valueProp: 'code',
                                        labelProp: 'name',
                                        placeholder: '',
                                        options: [],
                                        required: true,
                                        focus: true,
                                        onSelected: function(item, options) {
                                            var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                            $rootScope.$broadcast('newAreGesSelected', { item: item });
                                        }
                                    },
                                    hideExpression: function($viewValue, $modelValue, scope) {
                                    	return ((scope.model &&  scope.model.accountingReference && scope.model.accountingReference.id)?true:false);
                                    },
                                    controller: ['$scope', 'TramFactory', function($scope, TramFactory) {
                                    	var accountInstance = ((TramFactory && TramFactory.userAccountInstances && TramFactory.userAccountInstances.length == 1)?TramFactory.userAccountInstances[0]:undefined);
                                        if(!accountInstance){
                                        	accountInstance = (($scope.model && $scope.model.instanceCode && $scope.model.instanceCode.code)?$scope.model.instanceCode:undefined);
                                        }
                                        if(accountInstance && accountInstance.code){
                                        	TramFactory.getManagementCenters(accountInstance.code).then(function (data) {
                                        		$scope.to.options = JSOG.decode(data);
                                        		if($scope.to.options && $scope.model && (!$scope.model.areGes || !$scope.model.areGes.code)){
                                        			var areGesAux = $linq($scope.to.options).firstOrDefault(undefined, "x => x.code == '"+accountInstance.managementCenter+"'");
                                        			if(areGesAux){
                                        				$scope.model.areGes = areGesAux;
                                        			}
                                        		}
	                                   	   }).catch(function (error) {
	                                   		   $scope.to.options = [];
	                                   	   });
                                        }else{
                                        	$scope.to.options = [];
                                        }
                                        
                                        $scope.$on('newAccountingInstanceSelected', function(event, args) {
                                        	if(args.item && args.item.code) {
                                            	$scope.to.options.length = 0;
                                            	TramFactory.getManagementCenters(args.item.code).then(function (data) {
                                            		var mc = JSOG.decode(data);
                                            		_.forEach(mc, function(item){
                                               			$scope.to.options.push(item);
                                                	});
                                            		$scope.model.areGes = undefined;
     	                                   	    }).catch(function (error) {
    	                                   		});
                                            }
                                        });
                                        
                                    }]
                                },
                                {
                                    key: 'areGesName',
                                    type: 'annexaLabelRow',
                                    className: 'col-sm-12',
                                    templateOptions: {
                                        label: 'global.operationsAndInvoices.areGes',
                                        value:''
                                    },
                                    data: {
                                        informed: true,
                                        row: true
                                    },
                                    hideExpression: function($viewValue, $modelValue, scope) {
                                    	return ((scope.model &&  scope.model.accountingReference && scope.model.accountingReference.id)?false:true);
                                    },
                                    controller: ['$scope','TramFactory', '$filter', '$rootScope','GlobalDataFactory', function($scope, TramFactory, $filter, $rootScope, GlobalDataFactory) {
                                    	$scope.to.label = $filter('translate')('global.operationsAndInvoices.areGes');
                                    	$scope.$on('finishedAccountingReferenceSelected', function(event, args) {
                                            if(args.areGes) {
                                            	var areGesList = $linq($scope.fields).firstOrDefault(undefined,"x=>x.key == 'areGes'").templateOptions.options;
                                            	var areGesAux = undefined;
                                            	if(areGesList){
                                            		areGesAux = $linq(areGesList).firstOrDefault(undefined,"x => x.code == '"+args.areGes+"'");
                                            		if(areGesAux){
                                            			$scope.model.areGes = areGesAux;
                                            		}else{
                                            			$scope.model.areGes = {code: args.areGes};
                                            		}
                                            	}else{
                                            		$scope.model.areGes = {code: args.areGes};
                                            	}
                                            	if(areGesAux && areGesAux.name){
                                            		$scope.to.value = areGesAux.name;	
                                            	}else{
                                            		$scope.to.value = args.areGes;	
                                            	}
                                            }else if($scope.model && $scope.model.areGes && $scope.model.areGes.code){
                                        		if($scope.model.areGes.name){
                                        			$scope.to.value = $scope.model.areGes.name;
                                        		}else{
                                        			$scope.to.value = $scope.model.areGes.code;
                                        		}
                                            }else if($scope.model && $scope.model.areGes){
                                            	$scope.to.value = $scope.model.areGes;
                                            }
                                    	});
                                    }]
                                },
                                {
                                    key: 'operationType',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12',
                                    data: {
                                        informed:true,
                                        row:true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        optionsAttr: 'bs-options',
                                        ngOptions: 'option in to.options | filter: $select.search',
                                        label: 'global.operationType.operationType',
                                        valueProp: 'id',
                                        labelProp: 'language1',
                                        placeholder: '',
                                        options: [],
                                        required: true,
                                        focus: true,
                                        onSelected: function(item, options) {
                                            var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                            $rootScope.$broadcast('newOperationTypeSelected', { item: item });
                                        }
                                    },
                                    controller: ['$scope', 'Language', 'TramFactory', function($scope, Language, TramFactory) {
                                        $scope.to.labelProp = Language.getActiveColumn();
                                        $scope.to.options = ((TramFactory.procedureOperationTypes && TramFactory.procedureOperationTypes.length > 0)?TramFactory.procedureOperationTypes:[]);
                                    }]
                                },
                                {
                                    key: 'prevDef',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12',
                                    data: {
                                        informed:true,
                                        row:true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        optionsAttr: 'bs-options',
                                        ngOptions: 'option in to.options | filter: $select.search',
                                        label: 'global.operationsAndInvoices.prevDef',
                                        valueProp: 'id',
                                        labelProp: 'name',
                                        placeholder: '',
                                        options: [],
                                        required: true,
                                        focus: false
                                    },
                                    hideExpression: function($viewValue, $modelValue, scope) {
                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                    	return (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.modifyPreviousDefinitive)?false:true);
                                    },
                                    controller: ['$scope', 'GlobalDataFactory', '$filter', '$rootScope', function($scope, GlobalDataFactory, $filter, $rootScope) {
                                        $scope.to.options = [];
                                        _.forEach(GlobalDataFactory.previousDefinitive, function(item){
                                        	var itemAux = {id:item.id, name:$filter('translate')('global.operationsAndInvoices.'+item.name)};
                                        	$scope.to.options.push(itemAux);
                                        	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.perviousDefinitiveDefault){
                                        		if(item.id == $rootScope.app.configuration.operations_invoices_conf.perviousDefinitiveDefault){
                                        			$scope.model.prevDef = itemAux;
                                        		}
                                        	}
                                        });
                                    }]
                                },
                                {
                                    key: 'prevDefName',
                                    type: 'annexaLabelRow',
                                    className: 'col-sm-12',
                                    templateOptions: {
                                        label: 'global.operationsAndInvoices.prevDef',
                                        value:''
                                    },
                                    data: {
                                        informed: true,
                                        row: true
                                    },
                                    hideExpression: function($viewValue, $modelValue, scope) {
                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                    	return (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.modifyPreviousDefinitive)?true:false);
                                    },
                                    controller: ['$scope','TramFactory', '$filter', '$rootScope','GlobalDataFactory', function($scope, TramFactory, $filter, $rootScope, GlobalDataFactory) {
                                    	$scope.to.label = $filter('translate')('global.operationsAndInvoices.prevDef');
                                    	var id = (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.perviousDefinitiveDefault)?$rootScope.app.configuration.operations_invoices_conf.perviousDefinitiveDefault:"");
                                    	var pditem = $linq(GlobalDataFactory.previousDefinitive).firstOrDefault(undefined, "x => x.id == '"+id+"'");
                                    	if(pditem){
                                    		$scope.to.value = $filter('translate')('global.operationsAndInvoices.'+pditem.name);	
                                    	}else{
                                    		$scope.to.value = "";
                                    	}
                                    }]
                                },
                                {
                                    key: 'third',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12',
                                    data: {
                                        informed:true,
                                        row:true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        optionsAttr: 'bs-options',
                                        ngOptions: 'option in to.options | filter: $select.search',
                                        label: 'global.operationsAndInvoices.third',
                                        valueProp: 'id',
                                        labelProp: 'name',
                                        placeholder: '',
                                        options: [],
                                        required: true,
                                        focus: false
                                    },
                                    hideExpression: function($viewValue, $modelValue, scope) {
                                    	return ((scope.model && scope.model.operationType && scope.model.operationType.relatedThirds)?false:true);
                                    },
                                    controller: ['$scope', 'TramFactory', function($scope, TramFactory) {
                                        if(!$scope.to.options){
                                        	$scope.to.options = [];
                                        }else{
                                        	$scope.to.options.length = 0;
                                        }
                                        if(TramFactory.Dossier && TramFactory.Dossier.thirds && TramFactory.Dossier.thirds.length > 0){
                                        	_.forEach(TramFactory.Dossier.thirds, function(item){
                                        		var name = ((item.third && item.third.thirdType == "PHISICAL" && item.third.name)?item.third.name:"");
                                        		name = name + ((item.third && item.third.thirdType == "PHISICAL" && item.third.surename1)?" "+item.third.surename1:"");
                                        		name = name +((item.third && item.third.thirdType == "PHISICAL" && item.third.surename2)?" "+item.third.surename2:"");
                                        		name = name +((item.third && item.third.thirdType != "PHISICAL" && item.third.corporateName)?item.third.corporateName:"");
                                        		$scope.to.options.push({id:item.third.id, name:name});
	                                        });
                                        	if(TramFactory.Dossier.thirds.length == 1){
                                        		$scope.model.third = $scope.to.options[0];
                                        	}

                                        }
                                    }]
                                },
                                {
                                    key: 'subject',
                                    type: 'annexaInputRow',
                                    className: 'col-sm-12',
                                    data: {
                                        row: true,
                                        colClass: '',
                                        labelClass: 'label-strong',
                                        informed: true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        type: 'text',
                                        label: 'global.literals.description',
                                        required: true,
                                        focus: false,
                                        updateOnBlur:true
                                    }
                                },
                                {
                                    key: 'accountingReference',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12',
                                    data: {
                                        informed:true,
                                        row:true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        optionsAttr: 'bs-options',
                                        ngOptions: 'option in to.options | filter: $select.search',
                                        label: 'global.operationsAndInvoices.accountingReference',
                                        valueProp: 'id',
                                        labelProp: 'name',
                                        placeholder: '',
                                        options: [],
                                        required: false,
                                        focus: false,
                                        onSelected: function(item, options) {
                                            var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                            $rootScope.$broadcast('newAccountingReferenceSelected', { item: item });
                                        }
                                    },
                                    hideExpression: function($viewValue, $modelValue, scope) {
                                    	if(scope.model.operationType && scope.model.operationType.parentOperation == 'NONE'){
                                    		return true;
                                    	}else if(scope.model.operationType && scope.model.operationType.parentOperation == 'REQUIRED'){
                                    		return false;
                                    	}else{
                                    		var TramFactory = angular.element(document.body).injector().get('TramFactory');
                                            return ((TramFactory && TramFactory.accountingReferences && TramFactory.accountingReferences.length > 0)?false:true);
                                    	}
                                    },
                                    controller: ['$scope', 'TramFactory', '$rootScope', 'DialogsFactory', '$filter', function($scope, TramFactory, $rootScope, DialogsFactory, $filter) {
                                    	if(!$scope.to.options){
                                        	$scope.to.options = [];
                                        }else{
                                        	$scope.to.options.length = 0;
                                        }
                                        var accountInstance = ((TramFactory && TramFactory.userAccountInstances && TramFactory.userAccountInstances.length == 1)?TramFactory.userAccountInstances[0]:undefined);
                                        if(!accountInstance){
                                        	accountInstance = (($scope.model && $scope.model.instanceCode && $scope.model.instanceCode.code)?$scope.model.instanceCode:undefined);
                                        }
                                        if(accountInstance && TramFactory.accountingReferences && TramFactory.accountingReferences.length > 0){
                                        	_.forEach(TramFactory.accountingReferences, function(item){
                                        		if(($scope.model.instanceCode && item.code == $scope.model.instanceCode) || (accountInstance.code && item.code == accountInstance.code)){
                                        			$scope.to.options.push({id:item.name, name:item.name});
                                        		}
                                        	});
                                        }
                                        
                                        $scope.$on('newAccountingReferenceSelected', function(event, args) {
                                            if(args.item) {
                                            	var instanceCode = (($scope.model && $scope.model.instanceCode && $scope.model.instanceCode.code)?$scope.model.instanceCode.code:((TramFactory && TramFactory.userAccountInstances && TramFactory.userAccountInstances.length == 1)?TramFactory.userAccountInstances[0].code:undefined));
                                            	if(instanceCode){
	                                            	TramFactory.getBudgetItems(args.item.id, instanceCode).then(function (data) {
	                                            		$rootScope.$broadcast('modifyBudgetItemsAccountingReferenceSelected', { budgetItems: data, instanceCode:instanceCode });
	                                           	   }).catch(function (error) {
	                                           		   if(error && error.data && error.data.message && error.data.message != 'Error call to PDI'){
		                                       			   DialogsFactory.error(error.data.message);
		                                      		   }else{
		                                      			  DialogsFactory.error($filter('translate')('global.operationsAndInvoices.budgetsError'));
		                                      		   } 
	                                           		   $rootScope.$broadcast('modifyBudgetItemsAccountingReferenceSelectedError', { error: error });
	                                           	   });
                                            	}else{
                                            		 $rootScope.$broadcast('modifyBudgetItemsAccountingReferenceSelectedError', { error: '' });
                                            	}
                                            }
                                        });
                                        $scope.$on('newAccountingInstanceSelected', function(event, args) {
                                            if(args.item && args.item.code) {
                                            	$scope.to.options.length = 0;
                                            	_.forEach(TramFactory.accountingReferences, function(item){
                                            		if(item.code == args.item.code){
                                            			$scope.to.options.push({id:item.name, name:item.name});
                                            		}
                                            	});
                                            }
                                        });
                                        $scope.$on('newOperationTypeSelected', function(event, args) {
                                        	var instanceCode = (($scope.model && $scope.model.instanceCode && $scope.model.instanceCode.code)?$scope.model.instanceCode.code:((TramFactory && TramFactory.userAccountInstances && TramFactory.userAccountInstances.length == 1)?TramFactory.userAccountInstances[0].code:undefined));
                                        	$scope.to.options.length = 0;
                                        	if(instanceCode) {
                                                _.forEach(TramFactory.accountingReferences, function(item){
                                            		if(item.code == instanceCode){
                                            			if(args.item && args.item.id && args.item.previousOperationTypes && args.item.previousOperationTypes.length > 0){
                                            				var trobat = false;
                                            				_.forEach(args.item.previousOperationTypes, function(pot){
                                            					if(pot.previousOperationTypeId && pot.previousOperationTypeId == item.operationType){
                                            						trobat = true;
                                            					}
                                            				});
                                            				if(trobat){
                                            					$scope.to.options.push({id:item.name, name:item.name});
                                            				}
                                            			}else{
                                            				$scope.to.options.push({id:item.name, name:item.name});
                                            			}
                                            		}
                                            	});
                                            }
                                        });
                                    }]
                                },
                                {
                                    key: 'budgetItems',
                                    type: 'annexaComponent',
                                    className: 'col-sm-12',
                                    templateOptions: {
                                        type: 'annexa-budget-items'
                                    },
                                    data: {
                                        budgetItems: [],
                                        budgets:[],
                                        canEdit:true,
                                        emptyLabel: 'global.operationsAndInvoices.noBudgetItems',
                                        addLabel: 'global.operationsAndInvoices.addBudgetItem'
                                    }
                                },
                                {
                                    key: 'amount',
                                    type: 'annexaLabelRow',
                                    className: 'col-sm-12',
                                    templateOptions: {
                                        label: 'global.operationsAndInvoices.amount',
                                        value:''
                                    },
                                    data: {
                                        informed: true,
                                        row: true
                                    },
                                    controller: ['$scope','TramFactory', '$filter', '$rootScope','GlobalDataFactory', function($scope, TramFactory, $filter, $rootScope, GlobalDataFactory) {
                                    	$scope.to.label = $filter('translate')('global.operationsAndInvoices.amount');
                                    	$rootScope.$on('AnnexaChangeAmountBudget', function(event, args) {
                                            if(args && args.amount) {
                                            	$scope.to.value = (Math.round(args.amount * 100) / 100).toFixed(2)+'';
                                            }else{
                                                $scope.to.value = "0.00";
                                            }
                                        });
                                    	$scope.to.value = "0.00";
                                    	//TODO: calcular l'import total de les partides.
                                    }]
                                },
                                {
                                    key: 'createDocuments',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12',
                                    data: {
                                        informed:true,
                                        row:true,
                                        clear: function($event,model,key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        optionsAttr: 'bs-options',
                                        ngOptions: 'option in to.options | filter: $select.search',
                                        label: 'global.operationsAndInvoices.createDocument',
                                        valueProp: 'id',
                                        labelProp: 'description',
                                        placeholder: '',
                                        options: [],
                                        required: false,
                                        focus: false
                                    },
                                    controller: ['$scope', '$filter', function($scope, $filter) {
                                        $scope.to.options = [
                                            { id: true, description: $filter('translate')('global.literals.yes')},
                                            { id: false, description: $filter('translate')('global.literals.no')}
                                        ];
                                    }]
                                },
                                {
                                    key: 'dossierTransaction',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    data: {
                                        informed: true,
                                        row: true,
                                        clear: function ($event, model, key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if ($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        optionsAttr: 'bs-options',
                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                        label: 'global.literals.transaction',
                                        valueProp: 'id',
                                        labelProp: 'name',
                                        placeholder: '',
                                        options: []
                                    },
                                    validators: {
                                        "requiredOne": {
                                            "expression": function (viewValue, modelValue, scope) {
                                                var valid = true;

                                                return valid;
                                            }
                                        }
                                    },
                                    hideExpression:function($viewValue, $modelValue, scope) {
                                    	if(scope.model && (!scope.model.createDocuments || (scope.model.createDocuments && !scope.model.createDocuments.id))){
                                        	return true;
                                        }
                                        return false;
                                    },
                                    controller: ['$scope', 'Language', 'TramFactory', function ($scope, Language, TramFactory) {
                                        if(TramFactory.Dossier && TramFactory.Dossier.dossierTransactions){
                                            var transactions = $linq(TramFactory.Dossier.dossierTransactions).where("x => x.actual").toArray();
                                            if(transactions) {
                                                $scope.to.options.length = 0;
                                                _.forEach(transactions, function(value) {
                                                    $scope.to.options.push({id: value.id, name: value[Language.getActiveColumn()]});
                                                });
                                            }
                                        }
                                    }]
                                },
                                {
                                    key: 'docType',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12',
                                    templateOptions: {
                                        required: true,
                                        focus: true,
                                        label: 'global.literals.type',
                                        optionsAttr: 'bs-options',
                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                        valueProp: 'id',
                                        labelProp: 'language1',
                                        placeholder: '',
                                        options: []
                                    },
                                    controller: ['$scope', '$rootScope', 'Language', 'GlobalDataFactory', 'CustomFieldFactory', function($scope, $rootScope, Language, GlobalDataFactory, CustomFieldFactory) {
                                    	$scope.to.onSelected = function($item){
                                    		$rootScope.$broadcast('AnnexaSECDocChangeType');  
                                        };
                                        $scope.to.labelProp = Language.getActiveColumn();
                                    	$scope.to.options = angular.copy(GlobalDataFactory.allDocumentTypes);
                                    	if($scope.to.options && $scope.to.options.length == 1){
                                    		$scope.model.docType = $scope.to.options[0].id;
                                    	}
                                    	if($scope.to.options && $scope.to.options.length > 0){
                                    		_.forEach($scope.to.options, function (type) {
                                                var custom_fields = $linq(type.customFields).orderBy("x => x.viewOrder").toArray();
                                                var fieldsCF = [];
                                                _.forEach(custom_fields, function (field) {
                                                    var formCF = new AnnexaFormly();
                                                    formCF = CustomFieldFactory.addFormlyFieldRow(formCF, field.customField, field.required, type.id + '_' + field.customField.id);
                                                    formCF.fields[0].hideExpression = function ($viewValue, $modelValue, scope) {
                                                        if (!field.noEditable) {
                                                            if (!scope.model  || !scope.model.docType || !scope.model.docType || type.id != scope.model.docType) {
                                                                return true;
                                                            }
                                                        } else {
                                                            return true;
                                                        }
                                                    }


                                                    fieldsCF.push(formCF.fields[0]);
                                                    $scope.model['cf_' + type.id + '_' + field.customField.id] = CustomFieldFactory.calculateValueCustomField(field, $scope.model, ('cf_' + type.id + '_' + field.customField.id));
                                                });
                                                $scope.fields.push.apply($scope.fields, fieldsCF);
                                            });
                                    	}
                                    }],
                                    data: {
                                        row: true,
                                        informed: true
                                    },
                                    hideExpression: function ($viewValue, $modelValue, scope) {
                                        if(scope.model && (!scope.model.createDocuments || (scope.model.createDocuments && !scope.model.createDocuments.id))){
                                        	return true;
                                        }else{
	                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                                        var profiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();
	                                        if(profiles.length == 1){
	                                        	scope.model.profile = profiles[0].id;
	                                            return true;
	                                        }
                                        }
                                        return false;
                                    }
                                },
                                {
                                    key: 'documentTitle',
                                    type: 'annexaInputRow',
                                    className: 'col-sm-12',
                                    templateOptions: {
                                        type: 'text',
                                        label: 'global.literals.title',
                                        required: true,
                                        focus: false,
                                        sessionDate:undefined,
                                        acronymOrgan: undefined
                                    },
                                    data: {
                                        row: true,
                                        informed: true
                                    },
                                    controller: ['$scope', 'SecFactory', '$filter', 'CommonService', 'Language', '$rootScope', 'GlobalDataFactory',  function($scope, SecFactory, $filter, CommonService, Language, $rootScope, GlobalDataFactory) {
                                    	var labelProp = Language.getActiveColumn();
                                    	$scope.changeFunctionTitle = function(viewValue){
                                    		if($rootScope && ($rootScope.esetMode || ($rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.validate_title_document && $rootScope.app.configuration.validate_title_document.value))){
                                                if($scope.model.docType) {
                                                    var selectedType = $linq(GlobalDataFactory.allDocumentTypes).singleOrDefault(undefined, "x => x.id == " + $scope.model.docType);
                                                    if(selectedType) {
                                                    	if ($scope.lastSelectedType) {
                                                    		//Ya hay un tipo de documento seleccionado
                                                    		if(!$scope.model.documentTitle){
                                                    			$scope.model.documentTitle = "";
                                                    		}
                                                    		var pos = $scope.model.documentTitle.indexOf($scope.lastSelectedType);
                                                    		if (pos == 0) {
                                                    			//Encuentra el acronimo del tipo de documento anterior, lo reemplaza por el nuevo acronimo
                                                    			$scope.model.documentTitle = $scope.model.documentTitle.replace($scope.lastSelectedType, (selectedType.acronym + ' '+selectedType[labelProp]));
                                                    		} else {
                                                    			//No encuentra el acronimo del tipo de documento anterior, pone el nuevo acronimo al principio
                                                    			$scope.model.documentTitle = selectedType.acronym + ' '+selectedType[labelProp] + ' ' + $scope.model.documentTitle;
                                                    		}                                                		                                                		
                                                    	} else {
                                                    		//No hay un tipo de documento seleccionado
                                                    		if (viewValue) {
                                                    			if (viewValue.startsWith(selectedType.acronym + ' '+selectedType[labelProp])) {
                                                    				$scope.model.documentTitle = viewValue;
                                                    			} else {
                                                    				$scope.model.documentTitle = selectedType.acronym + ' '+selectedType[labelProp] + ' ' + viewValue;
                                                    			}
                                                    		} else {
                                                    			$scope.model.documentTitle = selectedType.acronym + ' '+selectedType[labelProp]+(($scope.to.acronymOrgan)?' '+$scope.to.acronymOrgan:'')+(($scope.to.sessionDate)?' '+$filter('date')(new Date($scope.to.sessionDate), 'ddMMyyyy'):'');
                                                            }
                                                    	}  
                                                    	$scope.lastSelectedType = selectedType.acronym + ' '+selectedType[labelProp];
                                                    }                                                
                                                }
                                            }else{
                                            	if($scope.model.docType) {
                                                	var selectedType = $linq(GlobalDataFactory.allDocumentTypes).singleOrDefault(undefined, "x => x.id == " + $scope.model.docType);
                                                    if(selectedType) {   
                                                    	if ($scope.lastSelectedType) {
                                                    		//Ya hay un tipo de documento seleccionado
                                                    		if(!$scope.model.documentTitle){
                                                    			$scope.model.documentTitle = "";
                                                    		}
                                                    		var pos = $scope.model.documentTitle.indexOf($scope.lastSelectedType);
                                                    		if (pos == 0) {
                                                    			//Encuentra el acronimo del tipo de documento anterior, lo reemplaza por el nuevo acronimo
                                                    			$scope.model.documentTitle = $scope.model.documentTitle.replace($scope.lastSelectedType, selectedType[labelProp]);
                                                    		} else {
                                                    			//No encuentra el acronimo del tipo de documento anterior, pone el nuevo acronimo al principio
                                                    			$scope.model.documentTitle =  selectedType[labelProp] + ' ' + $scope.model.documentTitle;
                                                    		}                                                		                                                		
                                                    	} else {
                                                    		//No hay un tipo de documento seleccionado
                                                    		if (viewValue) {
                                                    			if (viewValue.startsWith(selectedType[labelProp] + ' ')) {
                                                    				$scope.model.documentTitle = viewValue;
                                                    			} else {
                                                    				$scope.model.documentTitle = selectedType[labelProp] + ' ' + viewValue;
                                                    			}
                                                    		} else {
                                                    			$scope.model.documentTitle = selectedType[labelProp]+(($scope.to.acronymOrgan)?' '+$scope.to.acronymOrgan:'')+(($scope.to.sessionDate)?' '+$filter('date')(new Date($scope.to.sessionDate), 'dd-MM-yyyy'):'');
                                                            }
                                                    	}  
                                                    	$scope.lastSelectedType = selectedType[labelProp];
                                                    }
                                            	}
                                            }
                                        };
                                        $scope.changeFunctionTitle();
                                        $scope.to.onFocus = function(viewValue){
                                        	$scope.changeFunctionTitle(viewValue);
                                        }
                                        $scope.$on('AnnexaSECDocChangeType', function(event, args){
                                        	$scope.changeFunctionTitle();
                                        });
                                    }],
                                    hideExpression: function ($viewValue, $modelValue, scope) {
                                    	if(scope.model && (!scope.model.createDocuments || (scope.model.createDocuments && !scope.model.createDocuments.id))){
                                        	return true;
                                        }
                                        return false;
                                    }
                                },
                                {
                                    key: 'section',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12',
                                    templateOptions: {
                                        required: true,
                                        focus: false,
                                        label: 'global.literals.section',
                                        optionsAttr: 'bs-options',
                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                        valueProp: 'id',
                                        labelProp: 'language1',
                                        placeholder: '',
                                        options: []
                                    },
                                    controller: ['$scope', '$rootScope', 'Language', function($scope, $rootScope, Language) {
                                        $scope.to.labelProp = Language.getActiveColumn();
                                        $scope.to.options = $linq($rootScope.LoggedUser.userSections).distinctBy("x => x.section.id").select("x => x.section").toArray();
                                        if($scope.to.options && $scope.to.options.length == 1){
                                    		$scope.model.section = $scope.to.options[0].id;
                                    	}
                                    }],
                                    data: {
                                        row: true,
                                        informed: true
                                    },
                                    hideExpression: function ($viewValue, $modelValue, scope) {
                                    	if(scope.model && (!scope.model.createDocuments || (scope.model.createDocuments && !scope.model.createDocuments.id))){
                                        	return true;
                                        }else{
	                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                                        var sections = $linq($rootScope.LoggedUser.userSections).distinctBy("x => x.section.id").select("x => x.section").toArray();
	
	                                        if(sections.length == 1){
	                                        	scope.model.section = sections[0].id;
	                                            return true;
	                                        }
                                        }
                                        return false;
                                    }
                                },
                                {
                                    key: 'profile',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12',
                                    templateOptions: {
                                        required: true,
                                        focus: true,
                                        label: 'global.literals.profile',
                                        optionsAttr: 'bs-options',
                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                        valueProp: 'id',
                                        labelProp: 'language1',
                                        placeholder: '',
                                        options: []
                                    },
                                    controller: ['$scope', '$rootScope', 'Language', 'GlobalDataFactory', function($scope, $rootScope, Language, GlobalDataFactory) {
                                        $scope.to.labelProp = Language.getActiveColumn();
                                        $scope.to.options = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();
                                        if($scope.to.options && $scope.to.options.length == 1){
                                    		$scope.model.profile = $scope.to.options[0].id;
                                    	}else if($scope.to.options && $scope.to.options.length > 1){
                                    		var profiles = $linq($rootScope.LoggedUser.userDefaultEntityProfiles).where("x => x.entityType == 'EXP'").select("x => x.profile").toArray();
                                            if (profiles && profiles.length > 0) {
                                            	$scope.model.profile = profiles[0].id;
                                            }
                                    	}
                                    }],
                                    data: {
                                        row: true,
                                        informed: true
                                    },
                                    hideExpression: function ($viewValue, $modelValue, scope) {
                                    	if(scope.model && (!scope.model.createDocuments || (scope.model.createDocuments && !scope.model.createDocuments.id))){
                                        	return true;
                                        }else{
	                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                                        var profiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();
	
	                                        if(profiles.length == 1){
	                                        	scope.model.profile = profiles[0].id;
	                                            return true;
	                                        }
                                        }
                                        return false;
                                    }
                                },
                                {
                                    key: 'archiveClassification',
                                    type: 'annexaSelectTreeRow',
                                    className: 'col-sm-12',
                                    templateOptions: {
                                        label: 'global.literals.classificationBox',
                                        options: [],
                                        required: false,
                                        focus: false
                                    },
                                    controller:['$scope', 'CommonService', 'DccumentsFactory', 'Language', 'GlobalDataFactory', function($scope, CommonService, DccumentsFactory, Language, GlobalDataFactory) {
                                        $scope.to.options = CommonService.getTreeData(GlobalDataFactory.archiveClassifications, Language.getActiveColumn());
                                        $scope.to.labelProp = Language.getActiveColumn();
                                    }],
                                    data: {
                                        row: true,
                                        informed: true
                                    },
                                    hideExpression: function ($viewValue, $modelValue, scope) {
                                    	if(scope.model && (!scope.model.createDocuments || (scope.model.createDocuments && !scope.model.createDocuments.id))){
                                        	return true;
                                        }
                                        return false;
                                    }
                                }
                            ]
                        }
                    },
                    {
                        title: 'global.operationsAndInvoices.invoice_handler_finally',
                        key: 'deleteTransactionNewTram',
                        disabled: true,
                        isFinal: true,
                        disableNext: function(model, wizard) {
                            return true;
                        },
                        exitValidation: function(model) {
                            var selected = true;
                            return selected;
                        },
                        afterNext: function (step, wizard) {
                            var a = 0;
                        },
                        annexaFormly: {
                            fields: [
                            ]
                        }
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        seeOperationInvoices: {
            title: 'global.operationsAndInvoices.edit',
            size: 'modal-lg',
            icon: 'fa fa-check-square-o',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-4">',
                '       <annexa-label-value label="global.operationsAndInvoices.instance" value="modal.data.instanceCode"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-8">',
                '       <annexa-label-value label="global.operationType.operationType" value="modal.data.operationType[modal.languageColumn]"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-label-value label="global.operationsAndInvoices.prevDef" value="modal.data.prevDefAux"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-12" ng-if="modal.data.third && modal.data.third.id">',
                '       <annexa-label-value label="global.operationsAndInvoices.third" value="modal.data.third.completename"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-label-value label="global.literals.description" value="modal.data.subject"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-label-value label="global.operationsAndInvoices.accountingReference" value="modal.data.accountingReference"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-label-value label="global.operationsAndInvoices.accountingReferenceParent" value="modal.data.accountingReferenceParent"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <table class="table table-hover m-a-0 ">',
                '			<thead>',
                '				<tr>',
                '					<th style="width: 50%;"> <span translate="global.operationsAndInvoices.budgetItem"> </th>',
                '					<th style="width: 25%;"> <span translate="global.operationsAndInvoices.availableCredit"> </th>',
                '					<th style="width: 25%;"><span translate="global.operationsAndInvoices.amount"></th>',
                '				</tr>',
                '			  </thead>',
                '             <tbody>',
                '               <tr ng-repeat="budget in modal.data.budgetItems">',
                '                   <td>',
                '                       <span ng-if="budget.budget && budget.budget.name">{{budget.budget.name}}</span>',
                '                   </td>',
                '                   <td>',
                '                       <span ng-if="budget.budget && budget.budget.availableCredit">{{budget.budget.availableCredit}}</span>',
                '                   </td>',
                '                   <td>',
                '                       <span>{{budget.amount}}</span>',
                '                   </td>',
                '               </tr>',
                '             </tbody>',
                '       </table>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-label-value label="global.operationsAndInvoices.amount" value="modal.data.amount"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-4">',
                '       <annexa-label-value label="global.operationsAndInvoices.createDocument" value="modal.data.createDocumentAux"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-8" ng-if="modal.data.document && modal.data.document.id">',
                '       <annexa-href-permission-document document="modal.data.document" name-properties="\'seeDocument\'" origin="DOSSIER"></annexa-href-permission-document>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        createOperationInvoicesParameters: {
            title: 'global.operationsAndInvoices.parametersmodal',
            size: '',
            icon: 'fa fa-check-square-o',
            annexaFormly: {
                fields: []
            },
            submitModal: function () {
            }
        },
        editAddressCF: {
            title: 'global.literals.editAddressCF',
            size: '',
            icon: 'fa fa-at',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'country',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.thirds.literals.country',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifyCountryEvent', { item: $item });
                                    }
                                },
                                controller: function ($scope, Language, RestService, $rootScope) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    RestService.findAll('StreetCountry')
                                        .then(function(data) {
                                            $scope.to.options = JSOG.decode(data.data);
                                            if($scope.to.options && $scope.model.country && $scope.model.country.id) {
                                                var countries = $linq($scope.to.options).singleOrDefault(undefined, "x => x.id == " + $scope.model.country.id);
                                                if (countries) {
                                                    $scope.model.country = countries;
                                                }
                                            }
                                        }).catch(function(error) {
                                            console.error(error);
                                    });
                                }
                            },
                            {
                                key: 'validState',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    row: true,
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: '',
                                    label: 'global.thirds.literals.state',
                                    valueProp: 'id',
                                    labelProp: '',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifyStateEvent', { item: $item });
                                    }
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.hasStates){
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($q, $scope, Language, RestService, $rootScope) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.ngOptions = 'option in to.options | filter: {'+$scope.to.labelProp+': $select.search}';
                                    var calculateStates = function(){
                                        var statesAux = [];
                                        RestService.findAll('StreetState').then(function(data) {
                                            $scope.to.allStates = JSOG.decode(data.data);
                                            if($scope.model && $scope.model.country && $scope.model.country.id){
                                                statesAux = $.grep($scope.to.allStates, function (n, j) {if (n.country.id == $scope.model.country.id) {return n;}return;});
                                            }
                                            if(statesAux.length > 0){
                                                $scope.to.options = statesAux;
                                                $scope.model.hasStates = true;
                                                $scope.model.hasCities = true;
                                                $scope.model.hasStreets = true;
                                            }else{
                                                $scope.model.hasStates = false;
                                                $scope.model.hasCities = false;
                                                $scope.model.hasStreets = false;
                                                $scope.to.options = [];
                                            }
                                        }).catch(function(error) {
                                            $scope.to.allStates = [];
                                            $scope.to.options = [];
                                            $scope.model.hasStates = false;
                                            $scope.model.hasCities = false;
                                            $scope.model.hasStreets = false;
                                        });
                                    }
                                    if($scope.model && $scope.model.country && $scope.model.country.id){
                                        calculateStates();
                                    }
                                    $rootScope.$on('modifyCountryEvent', function(event, args) {
                                        $scope.model.validState = undefined;
                                        if($scope.to.allStates && $scope.to.allStates.length > 0) {
                                            var statesAux = [];
                                            statesAux = $.grep($scope.to.allStates, function (n, j) {if (n.country.id == $scope.model.country.id) {return n;}return;});
                                            if(statesAux.length > 0){
                                                $scope.to.options = statesAux;
                                                $scope.model.hasStates = true;
                                                $scope.model.hasCities = true;
                                                $scope.model.hasStreets = true;
                                            }else{
                                                $scope.model.hasStates = false;
                                                $scope.model.hasCities = false;
                                                $scope.model.hasStreets = false;
                                                $scope.to.options = [];
                                            }
                                        }else{
                                            calculateStates();
                                        }
                                    });
                                }

                            },
                            {
                                key: 'stateAddress',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: '',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.state',
                                    required: false,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(!scope.model.hasStates){
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($q, $scope, Language, RestService, $rootScope) {
                                    $rootScope.$on('modifyCountryEvent', function(event, args) {
                                        $scope.model.stateAddress = undefined;
                                    });
                                }
                            },
                            {
                                key: 'validCity',
                                type: 'annexaSelectRow',
                                className: 'col-sm-9 daughter-label-strong',
                                data: {
                                    row: true,
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: '',
                                    label: 'global.literals.city',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifyCityEvent', { item: $item });
                                    }
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.hasCities){
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($q, $scope, ThirdFactory, $rootScope) {
                                    $scope.to.ngOptions = 'option in to.options | filter: {'+$scope.to.labelProp+': $select.search}';
                                    var calculateCities = function(){
                                        ThirdFactory.getCitiesByState($scope.model.validState.id).then(function (data) {
                                            var citiesAux = JSOG.decode(data);
                                            citiesAux = new SortedArray(citiesAux, 'name').sort();
                                            if (citiesAux.length > 0) {
                                                $scope.to.options = citiesAux;
                                                $scope.model.hasCities = true;
                                                $scope.model.hasStreets = true;
                                            } else {
                                                $scope.to.options = [];
                                                $scope.model.hasCities = false;
                                                $scope.model.hasStreets = false;
                                            }
                                        }).catch(function (error) {
                                            $scope.to.options = [];
                                            $scope.model.hasCities = false;
                                            $scope.model.hasStreets = false;
                                        });
                                    }
                                    if($scope.model && $scope.model.validState && $scope.model.validState.id){
                                        calculateCities();
                                    }
                                    $rootScope.$on('modifyStateEvent', function(event, args) {
                                        $scope.model.validCity = undefined;
                                        if($scope.model.validState && $scope.model.validState.id) {
                                            calculateCities();
                                        }else{
                                            $scope.to.options = [];
                                            $scope.model.hasCities = false;
                                            $scope.model.hasStreets = false;
                                        }
                                    });

                                    $rootScope.$on('modifyCountryEvent', function(event, args) {
                                        $scope.model.validCity = undefined;
                                        $scope.to.options = [];
                                    });
                                }
                            },
                            {
                                key: 'city',
                                type: 'annexaInputRow',
                                className: 'col-sm-9',
                                data: {
                                    row: true,
                                    colClass: ' ',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.city',
                                    required: false,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(!scope.model.hasCities){
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($q, $scope, Language, RestService, $rootScope) {
                                    $rootScope.$on('modifyStateEvent', function(event, args) {
                                        $scope.model.city = undefined;
                                    });

                                    $rootScope.$on('modifyCountryEvent', function(event, args) {
                                        $scope.model.city = undefined;
                                    });
                                }
                            },
                            {
                                key: 'postalCode',
                                type: 'annexaInputRow',
                                className: 'col-sm-3',
                                data: {
                                    row: true,
                                    colClass: ' ',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.postalCode',
                                    required: false,
                                    focus: false,
                                    updateOnBlur:true
                                }
                            },
                            {
                                key: 'streetType',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    row: true,
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.thirds.literals.streetType',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.hasStreetTypes){
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($q,$scope, Language, RestService, $rootScope) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.streetTypeAux = [];
                                    RestService.findAll('StreetStreetType').then(function (data) {
                                        var streetTypeAux = JSOG.decode(data.data);
                                        if (streetTypeAux.length > 0) {
                                            $scope.to.options = streetTypeAux;
                                            $scope.model.hasStreetTypes = true;
                                        } else {
                                            $scope.to.options = [];
                                            $scope.model.hasStreetTypes = false;
                                        }
                                    }).catch(function (error) {
                                        $scope.model.hasStreetTypes = false;
                                        $scope.to.options = [];
                                    });
                                    $rootScope.$on('modifyStreetEvent', function (event, args) {
                                        if($scope.model.validStreet && $scope.model.validStreet.streetType &&  $scope.model.validStreet.streetType.id) {
                                            $scope.model.streetType = $scope.model.validStreet.streetType;
                                        }
                                    });
                                }
                            },
                            {
                                key: 'street',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' ',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.streetName',
                                    required: false,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                controller: function ($q, $scope, Language, RestService, $rootScope) {
                                    $rootScope.$on('modifyCityEvent', function(event, args) {
                                        $scope.model.street = undefined;
                                    });

                                    $rootScope.$on('modifyStateEvent', function(event, args) {
                                        $scope.model.street = undefined;
                                    });

                                    $rootScope.$on('modifyCountryEvent', function(event, args) {
                                        $scope.model.street = undefined;
                                    });
                                }
                            },
                            {
                                key: 'numberAddres',
                                type: 'annexaInputRow',
                                className: 'col-sm-20',
                                data: {
                                    row: true,
                                    colClass: ' ',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.number',
                                    required: false,
                                    focus: false,
                                    updateOnBlur:true
                                }
                            },
                            {
                                key: 'block',
                                type: 'annexaInputRow',
                                className: 'col-sm-20',
                                data: {
                                    row: true,
                                    colClass: ' ',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.block',
                                    required: false,
                                    focus: false,
                                    updateOnBlur:true
                                }
                            },
                            {
                                key: 'stair',
                                type: 'annexaInputRow',
                                className: 'col-sm-20',
                                data: {
                                    row: true,
                                    colClass: '',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.stair',
                                    required: false,
                                    focus: false,
                                    updateOnBlur:true
                                }
                            },
                            {
                                key: 'floor',
                                type: 'annexaInputRow',
                                className: 'col-sm-20',
                                data: {
                                    row: true,
                                    colClass: ' ',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.floor',
                                    required: false,
                                    focus: false,
                                    updateOnBlur:true
                                }
                            },
                            {
                                key: 'door',
                                type: 'annexaInputRow',
                                className: 'col-sm-20',
                                data: {
                                    row: true,
                                    colClass: ' ',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.door',
                                    required: false,
                                    focus: false,
                                    updateOnBlur:true
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        }
    });
